import { Grid } from "@mui/material";
import { useOutletContext } from "react-router";
import ChartGenderRace from "./ChartGenderRace";
import ReportSummary from "./ReportSummary";
import ChartGradeCounts from "./ChartGradeCounts";
//import ChartStudentProgramCounts from "./ChartStudentProgramCounts";
//import ChartTransportationCounts from "./ChartTransportationCounts";
import { useMemo } from "react";
import { useGetProgramReport } from "../../services/hooks/partnerReportsHook";

export default function ReportsDemographics() {
  const [slicers] = useOutletContext();

  const query = useGetProgramReport(slicers);
  const data = query?.data?.data;

  const slicerData = useMemo(() => {
    return data ? data : {};
  }, [data, slicers]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReportSummary status={query?.isLoading} slicerData={slicerData} />
        </Grid>
        <Grid item xs={12}>
          <ChartGenderRace
            status={query?.isLoading}
            slicerData={slicerData?.races}
          />
        </Grid>
        <Grid item xs={12}>
          <ChartGradeCounts
            status={query?.isLoading}
            slicerData={slicerData?.grade}
          />
        </Grid>
        {/* 
        Removing Student Programs chart until we can ensure accuracy
        <Grid item xs={12} md={5}>
          <ChartStudentProgramCounts slicers={slicers} />
        </Grid> */}
        {/* <Grid item xs={12}>
          <ChartTransportationCounts slicers={slicers} />
        </Grid> */}
      </Grid>
    </>
  );
}
