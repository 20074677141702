import React, { useState, useMemo } from "react";
import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useGetProviders } from "../../services/hooks/providersHook";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../services/hooks/useLocalStorage";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import Error from "../common/Error";
import { ManageProviders } from "../../services/permissions";
import { useUser } from "../../services/contexts/userContext";
import { ScrollToTopOnMount } from "../../services/utilities";
import { getComparator, stableSort } from "../../services/tableFunctions";

export default function Programs() {
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const navigate = useNavigate();
  const { user } = useUser();

  const providerQuery = useGetProviders();
  const [searchInput, setSearchInput] = useState("");
  const [showInactive, setShowInactive] = useState(
    "providerListShowInactive",
    false
  );
  const filteredProviders = useMemo(() => {
    let copyProviders = providerQuery?.data?.data;

    if (searchInput?.length > 0) {
      copyProviders = copyProviders?.filter((p) => {
        return p.name.toLowerCase().includes(searchInput?.toLowerCase());
      });
    }
    if (!showInactive) {
      copyProviders = copyProviders?.filter((p) => p.active);
    }

    return copyProviders;
  }, [providerQuery, searchInput, showInactive]);

  //table
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useLocalStorage(
    "PartnersListRowsPerPage",
    10
  );

  const tableHeadCells = [
    {
      id: "sortName",
      numeric: false,
      label: "Name",
    },
    {
      id: "sortIconUrl",
      numeric: false,
      label: "Icon",
    },
    {
      id: "sortPartnerName",
      numeric: false,
      label: "Partner",
    },
    {
      id: "sortActive",
      numeric: false,
      label: "Active",
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {tableHeadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography>{headCell.label}</Typography>
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Styles
  const providerTitleStyles = {
    fontSize: "1.2rem",
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h1">Providers</Typography>
        </Grid>
        {user?.permissions?.includes(ManageProviders) && (
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/providers/create");
              }}
              startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
            >
              Create Provider
            </Button>
          </Grid>
        )}
      </Grid>

      <br />

      <Paper>
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={commonStyles.paperPadding}
        >
          {/* Search bar */}
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                value={searchInput}
                id="program-search"
                label="Search"
                variant="outlined"
                inputProps={{
                  autoComplete: "off",
                }}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                  setPage(0);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!showInactive}
                  onChange={(e) => {
                    setShowInactive(e.target.checked);
                    setPage(0);
                  }}
                />
              }
              label="Show Inactive"
            />
          </Grid>
        </Grid>

        <Error
          message={"There was a problem loading partners."}
          query={providerQuery}
        />

        <>
          <TableContainer>
            <Table
              aria-label="provider table"
              size="small"
              sx={commonStyles.tableStyles}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={filteredProviders?.length ?? 0}
              />

              <TableBody>
                {providerQuery.isSuccess && filteredProviders?.length == 0 && (
                  <TableRow>
                    <TableCell colSpan="8">
                      <Typography
                        sx={{
                          m: theme.spacing(2),
                          color: theme.palette.text.secondary,
                        }}
                      >
                        <strong>No providers found</strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {providerQuery.isLoading &&
                  Array(5)
                    .fill(0)
                    .map((_, index) => (
                      <TableRow key={index}>
                        <TableCell colSpan="8">
                          <Skeleton variant="rect" height={60} />
                        </TableCell>
                      </TableRow>
                    ))}
                {filteredProviders &&
                  stableSort(filteredProviders, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((provider, index) => (
                      <TableRow
                        key={index}
                        hover={true}
                        sx={{
                          cursor:
                            user?.permissions?.includes(ManageProviders) &&
                            "pointer",
                          backgroundColor:
                            !provider.active &&
                            theme.palette.background.default,
                        }}
                        onClick={() => {
                          if (user?.permissions?.includes(ManageProviders)) {
                            navigate(`/providers/edit/${provider.providerId}`);
                          }
                        }}
                      >
                        <TableCell>
                          <Typography sx={providerTitleStyles}>
                            {provider.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {provider.iconUrl && (
                              <img
                                style={{ maxHeight: 50, maxWidth: 100 }}
                                src={provider.iconUrl}
                                alt="Provider icon"
                              />
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" component="span">
                            {provider?.partner?.name}{" "}
                          </Typography>
                          {user?.sharedPartners?.includes(
                            provider?.partnerId
                          ) && (
                            <Chip
                              size="small"
                              color="secondary"
                              label="shared"
                              variant="outlined"
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {provider.active ? "Active" : "Inactive"}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={filteredProviders?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </Paper>
    </>
  );
}
