import { useEffect, useState } from "react";
import {
  faHome,
  faUserPen,
  faBook,
  faInfoCircle,
  faUsers,
  faPersonChalkboard,
  faHandshakeSimple,
  faChildren,
  faChartLine,
  faChartBar,
  faTable,
} from "@fortawesome/pro-solid-svg-icons";
import { useUser } from "../../services/contexts/userContext";
import {
  // ViewPrograms,
  // ManagePrograms,
  // DraftPrograms,
  ViewActivities,
  // ManageActivities,
  ViewUsers,
  // ManageUsers,
  ViewProviders,
  // ManageProviders,
  ViewPartners,
  // ManagePartners,
  ViewRoster,
  ViewReports,
  // ViewFRL,
  // InstructPrograms,
  // TakeAttendance,
  // RegisterStudents,
} from "../../services/permissions";
import { useGetPartnerDsaDates } from "../../services/hooks/partnersHook";
import dayjs from "dayjs";

// custom icon
import { library } from "@fortawesome/fontawesome-svg-core";
import { fasBeyondTheBell } from "../images/icons/fasBeyondTheBell";
library.add(fasBeyondTheBell);

export default function useNavItems() {
  const [validDsa, setValidDsa] = useState(true);
  const { user } = useUser();
  
  const dsaQuery = useGetPartnerDsaDates(
    user?.partners?.[0] ? user?.partners?.[0] : -1
  );
  const dsaData = dsaQuery?.data?.data;

  useEffect(() => {
    if (dsaData) {
      if (!dayjs(dayjs().format("YYYY-MM-DD")).isBetween(
        dsaData?.dsaStartDate,
        dsaData?.dsaEndDate,
        "day", // granularity
        [] // inclusivity of start and end dates
      )) {
        setValidDsa(false);
      }
    }
  }, [dsaData]);

  return user?.validUser === false
    ? []
    : user?.role !== "DSA Viewer"
    ? [
        {
          name: "Home",
          icon: faHome,
          to: "/",
          enabled: true,
        },
        {
          name: "Programs",
          icon: fasBeyondTheBell,
          to: "programs",
          enabled: true,
        },
        {
          name: "Roster",
          icon: faChildren,
          to: "roster",
          enabled: user?.permissions?.includes(ViewRoster),
        },
        {
          name: "Reports",
          icon: faChartLine,
          to: "reports/",
          enabled: user?.permissions?.includes(ViewReports),
          subMenu: [
            {
              name: "Program",
              icon: faChartBar,
              to: "reports/program",
              enabled: user?.permissions?.includes(ViewReports),
            },
            {
              name: "Instructor",
              icon: faTable,
              to: "reports/instructor",
              enabled: user?.permissions?.includes(ViewReports),
            },
          ],
        },
        {
          name: "Activities",
          icon: faBook,
          to: "activities",
          enabled: user?.permissions?.includes(ViewActivities),
        },
        {
          name: "Users",
          icon: faUsers,
          to: "users",
          enabled: user?.permissions?.includes(ViewUsers),
        },
        {
          name: "Providers",
          icon: faPersonChalkboard,
          to: "providers",
          enabled: user?.permissions?.includes(ViewProviders),
        },
        {
          name: "Partners",
          icon: faHandshakeSimple,
          to: "partners",
          enabled: user?.permissions?.includes(ViewPartners),
        },
        {
          name: "Resources",
          icon: faInfoCircle,
          to: "resources",
          enabled: true,
        },
        {
          name: "Personalization",
          icon: faUserPen,
          to: "personalization",
          enabled: true,
        },
      ]
    : [
        //DSA Viewer Nav Items
        {
          name: "Home",
          icon: faHome,
          to: "/",
          enabled: true,
        },
        {
          name: "Reports",
          icon: faTable,
          to: "reports/instructor",
          enabled: validDsa,
        },
        {
          name: "Resources",
          icon: faInfoCircle,
          to: "resources",
          enabled: true,
        },
        {
          name: "Personalization",
          icon: faUserPen,
          to: "personalization",
          enabled: true,
        },
      ];
}
