import { Grid, Link, Paper, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { useOutletContext } from "react-router";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useGetProgramReport } from "../../services/hooks/partnerReportsHook";
import ReportSummary from "./ReportSummary";

export default function ReportsAttendance() {
  const theme = useTheme();
  const [slicers] = useOutletContext();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const query = useGetProgramReport(slicers);
  const data = query?.data?.data;

  const slicerData = useMemo(() => {
    return data ? data : {};
  }, [data, slicers]);

  const programs = useMemo(() => {
    return slicerData?.programs;
  }, [slicerData]);

  const columns = [
    { field: "provider", headerName: "Provider", flex: 2.25 },
    {
      field: "title",
      headerName: "Program",
      renderCell: (val) => {
        return <Link href={`/programs/${val.id}`}>{val.value}</Link>;
      },
      flex: 3,
    },
    { field: "activityTypeName", headerName: "Type", flex: 1 },
    { field: "locationName", headerName: "Location", flex: 1.5 },
    { field: "sessionName", headerName: "Session", align: "right", flex: 0.75 },
    { field: "programDays", headerName: "Days", align: "right", flex: 0.5 },
    {
      field: "attendance",
      headerName: "Attendance",
      align: "right",
      flex: 1,
      valueFormatter: (val) => {
        return (val.value * 100).toFixed(1) + "%";
      },
    },
  ];

  const gridStyles = {
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "none",
    borderRadius: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderRadius: 0,
    },
    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle":
      // allowing for wrapping of long column header titles
      {
        lineHeight: "1rem",
        whiteSpace: "normal",
      },
    "& .MuiDataGrid-columnHeader:last-of-type": {
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ReportSummary status={query?.isLoading} slicerData={slicerData} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <DataGridPremium
            rows={programs ? programs : []}
            columns={columns}
            pagination
            pageSizeOptions={[10, 25, 50]}
            disableRowSelectionOnClick={true}
            autoHeight
            sx={gridStyles}
            columnHeaderHeight={64}
            getRowId={(row) => row.programId}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
