import { Grid } from "@mui/material";
import { useOutletContext } from "react-router";
import TableMarks from "./TableMarks";

export default function InstructorReportsMarks() {
  const [slicers] = useOutletContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableMarks slicers={slicers} />
      </Grid>
    </Grid>
  );
}
