import {
  blue,
  deepPurple,
  purple,
  deepOrange,
  green,
  grey,
  indigo,
  orange,
  red,
  yellow,
  amber,
  pink,
  cyan,
} from "@mui/material/colors";
import { darken, lighten } from "@mui/material/styles";
import "@fontsource/lacquer";
import "@fontsource/russo-one";
import "@fontsource-variable/open-sans/standard.css";
import "@fontsource-variable/overpass";
import "@fontsource/berkshire-swash/latin-400.css";

const lacquerTypography = {
  name: "Lacquer",
  fontFamily: "Textile, monospace",
  h1: {
    fontFamily: "Lacquer, 'Trebuchet MS'",
    fontWeight: 700,
    fontSize: "4rem",
  },
  h2: {
    fontFamily: "Lacquer, 'Trebuchet MS'",
    fontWeight: 700,
    fontSize: "3.5rem",
  },
  h3: {
    fontFamily: "Lacquer, 'Trebuchet MS'",
    fontVariationSettings: '"wdth" 75',
    fontWeight: 700,
  },
  h4: {
    fontFamily: "Lacquer, 'Trebuchet MS'",
    fontVariationSettings: '"wdth" 75',
    fontWeight: 700,
  },
  h5: {
    fontFamily: "Lacquer, 'Trebuchet MS'",
    fontVariationSettings: '"wdth" 75',
    fontWeight: 700,
  },
  h6: {
    fontFamily: "Lacquer, 'Trebuchet MS'",
    fontVariationSettings: '"wdth" 75',
  },
};

const openSansTypography = {
  name: "openSans",
  fontFamily: "'Open Sans Variable', sans-serif",
  h1: {
    fontFamily: "Open Sans Variable",
    fontVariationSettings: "'wdth' 75",
    fontWeight: 700,
    fontSize: "4rem",
  },
  h2: {
    fontFamily: "Open Sans Variable",
    fontVariationSettings: '"wdth" 75',
    fontWeight: 700,
    fontSize: "3.5rem",
  },
  h3: {
    fontFamily: "Open Sans Variable",
    fontVariationSettings: '"wdth" 75',
    fontWeight: 700,
  },
  h4: {
    fontFamily: "Open Sans Variable",
    fontVariationSettings: '"wdth" 75',
    fontWeight: 700,
  },
  h5: {
    fontFamily: "Open Sans Variable",
    fontVariationSettings: '"wdth" 75',
    fontWeight: 700,
  },
  h6: {
    fontFamily: "Open Sans Variable",
    fontVariationSettings: '"wdth" 75',
    fontWeight: 700,
  },
};

const overpassTypography = {
  name: "overpass",
  fontFamily: "'Overpass Variable', sans-serif",
  h1: {
    fontFamily: "'Overpass Variable', sans-serif",
    fontWeight: 900,
    fontSize: "4rem",
  },
  h2: {
    fontFamily: "'Overpass Variable', sans-serif",
    fontWeight: 900,
    fontSize: "3.5rem",
  },
  h3: { fontFamily: "'Overpass Variable', sans-serif", fontWeight: 900 },
  h4: { fontFamily: "'Overpass Variable', sans-serif", fontWeight: 900 },
  h5: { fontFamily: "'Overpass Variable', sans-serif", fontWeight: 900 },
  h6: { fontFamily: "'Overpass Variable', sans-serif", fontWeight: 900 },
};

const russoTypography = {
  name: "Russo One",
  fontFamily: "monospace, sans-serif",
  fontWeight: "100",
  h1: {
    fontFamily: "'Russo One', sans-serif",
    fontWeight: 500,
    fontSize: "4rem",
  },
  h2: {
    fontFamily: "'Russo One', sans-serif",
    fontWeight: 500,
    fontSize: "3.5rem",
  },
  h3: { fontFamily: "'Russo One', sans-serif", fontWeight: 500 },
  h4: { fontFamily: "'Russo One', sans-serif", fontWeight: 500 },
  h5: { fontFamily: "'Russo One', sans-serif", fontWeight: 500 },
  h6: { fontFamily: "'Russo One', sans-serif", fontWeight: 500 },
  button: { fontFamily: "'Russo One'" },
};
//todo keep separating all shadows
const softShadows = [
  "none",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 12px 13px rgba(0, 0, 0, 0.072)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 14px 15px rgba(0, 0, 0, 0.072)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 20px 25px rgba(0, 0, 0, 0.086)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 23px 28px rgba(0, 0, 0, 0.086)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 26px 33.4px rgba(0, 0, 0, 0.086)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 29px 33.4px rgba(0, 0, 0, 0.086)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 50px 50px rgba(0, 0, 0, 0.12)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 60px 60px rgba(0, 0, 0, 0.12)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 70px 70px rgba(0, 0, 0, 0.12)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 80px 80px rgba(0, 0, 0, 0.12)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 90px 80px rgba(0, 0, 0, 0.14)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.16)",
  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.20)",
];

const components = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 999,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 16,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      rounded: {
        borderRadius: 16,
        overflow: "hidden",
      },
    },
  },
};

export const themes = [
  /* ██████╗ ███████╗███████╗ █████╗ ██╗   ██╗██╗  ████████╗
    ██╔══██╗██╔════╝██╔════╝██╔══██╗██║   ██║██║  ╚══██╔══╝
    ██║  ██║█████╗  █████╗  ███████║██║   ██║██║     ██║   
    ██║  ██║██╔══╝  ██╔══╝  ██╔══██║██║   ██║██║     ██║   
    ██████╔╝███████╗██║     ██║  ██║╚██████╔╝███████╗██║   
    ╚═════╝ ╚══════╝╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚═╝     */
  {
    name: "TPS (default)",
    shadows: softShadows,
    palette: {
      mode: "light",
      background: {
        default: "#f4f4f4",
        paper: "#fff",
      },
      primary: {
        main: "#0276b2",
      },
      secondary: {
        main: "#6922ab",
      },
      text: {
        primary: grey[900],
        deemphasized: grey[600],
      },
      error: {
        main: red[300],
      },
      nav: {
        main: "#26262c",
        light: "#303136",
        dark: "#18181b",
        contrastText: "#fff",
        contrastTextSecondary: "#c0c0c0",
      },
      chart: [
        "#0276b2",
        "#8C42AD",
        green[600],
        pink[700],
        orange[600],
        cyan[500],
        indigo[700],
      ],
    },
    typography: openSansTypography,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 999,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 16,
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: 0,
          },
        },
      },
    },
  },
  /* ██████╗██╗     ███████╗ █████╗ ███╗   ██╗     ██████╗ ██████╗ ███████╗███████╗███╗   ██╗
    ██╔════╝██║     ██╔════╝██╔══██╗████╗  ██║    ██╔════╝ ██╔══██╗██╔════╝██╔════╝████╗  ██║
    ██║     ██║     █████╗  ███████║██╔██╗ ██║    ██║  ███╗██████╔╝█████╗  █████╗  ██╔██╗ ██║
    ██║     ██║     ██╔══╝  ██╔══██║██║╚██╗██║    ██║   ██║██╔══██╗██╔══╝  ██╔══╝  ██║╚██╗██║
    ╚██████╗███████╗███████╗██║  ██║██║ ╚████║    ╚██████╔╝██║  ██║███████╗███████╗██║ ╚████║
    ╚═════╝╚══════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═══╝     ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝  ╚═══╝  */
  {
    name: "Clean Green",
    shadows: softShadows,
    palette: {
      mode: "light",
      background: {
        default: "#f2f3f7",
        paper: "#fff",
      },
      primary: {
        main: "#11966f",
      },
      secondary: {
        main: "#6922ab",
      },
      text: {
        primary: grey[900],
        deemphasized: grey[600],
      },
      nav: {
        main: "#222326",
        light: "#303136",
        dark: "#17171a",
        contrastText: "#fff",
        contrastTextSecondary: "#c0c0c0",
      },
      chart: [
        "#11966f",
        deepOrange[500],
        blue[600],
        pink[700],
        purple[600],
        cyan[500],
        indigo[700],
      ],
    },
    typography: openSansTypography,
    components: components,
  },
  /* ██████╗ ██████╗ ███████╗███╗   ███╗ ██████╗ ███╗   ██╗ █████╗ ██╗   ██╗████████╗
    ██╔════╝██╔═══██╗██╔════╝████╗ ████║██╔═══██╗████╗  ██║██╔══██╗██║   ██║╚══██╔══╝
    ██║     ██║   ██║███████╗██╔████╔██║██║   ██║██╔██╗ ██║███████║██║   ██║   ██║   
    ██║     ██║   ██║╚════██║██║╚██╔╝██║██║   ██║██║╚██╗██║██╔══██║██║   ██║   ██║   
    ╚██████╗╚██████╔╝███████║██║ ╚═╝ ██║╚██████╔╝██║ ╚████║██║  ██║╚██████╔╝   ██║   
     ╚═════╝ ╚═════╝ ╚══════╝╚═╝     ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝  ╚═╝ ╚═════╝    ╚═╝   */
  {
    name: "Cosmonaut",
    shadows: softShadows,
    palette: {
      mode: "dark",
      background: {
        default: "#282a33",
        paper: "#11121c",
      },
      primary: {
        main: red[700],
      },
      secondary: {
        main: "#fff",
      },
      text: {
        primary: "#fff",
        deemphasized: grey[300],
      },
      error: {
        main: pink[800],
      },
      nav: {
        main: "#171924",
        light: lighten("#171924", 0.1),
        dark: darken("#171924", 0.1),
        contrastText: "#fff",
        contrastTextSecondary: "#c0c0c0",
      },
      chart: [
        red[500],
        amber[400],
        blue[400],
        green[300],
        purple[200],
        cyan[300],
        orange[400],
        red[400],
      ],
    },
    typography: russoTypography,
    components: {
      ...components,
      ...{
        MuiListItemText: {
          styleOverrides: {
            primary: {
              fontFamily: "'Russo One'",
            },
          },
        },
      },
    },
  },
  /*██╗  ██╗██████╗  █████╗ ██╗  ██╗███████╗███╗   ██╗
    ██║ ██╔╝██╔══██╗██╔══██╗██║ ██╔╝██╔════╝████╗  ██║
    █████╔╝ ██████╔╝███████║█████╔╝ █████╗  ██╔██╗ ██║
    ██╔═██╗ ██╔══██╗██╔══██║██╔═██╗ ██╔══╝  ██║╚██╗██║
    ██║  ██╗██║  ██║██║  ██║██║  ██╗███████╗██║ ╚████║
    ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═══╝ */
  {
    name: "Kraken",
    shadows: softShadows,
    palette: {
      mode: "dark",
      background: {
        default: lighten("#011425", 0.2),
        paper: darken("#011425", 0.2),
      },
      primary: {
        main: "#82dbd9",
      },
      secondary: {
        main: lighten("#e9072b", 0.1),
      },
      text: {
        primary: "#fff",
        deemphasized: grey[300],
      },
      nav: {
        main: "#011425",
        light: lighten("#011425", 0.2),
        dark: darken("#011425", 0.2),
        contrastText: "#fff",
        contrastTextSecondary: "#c0c0c0",
      },
      chart: [
        lighten("#e9072b", 0.2),
        blue[200],
        green[300],
        yellow[200],
        pink[200],
        cyan[300],
        orange[400],
      ],
    },
    typography: openSansTypography,
    components: components,
  },
  /*███╗   ███╗██╗██████╗ ███╗   ██╗██╗ ██████╗ ██╗  ██╗████████╗
    ████╗ ████║██║██╔══██╗████╗  ██║██║██╔════╝ ██║  ██║╚══██╔══╝
    ██╔████╔██║██║██║  ██║██╔██╗ ██║██║██║  ███╗███████║   ██║   
    ██║╚██╔╝██║██║██║  ██║██║╚██╗██║██║██║   ██║██╔══██║   ██║   
    ██║ ╚═╝ ██║██║██████╔╝██║ ╚████║██║╚██████╔╝██║  ██║   ██║   
    ╚═╝     ╚═╝╚═╝╚═════╝ ╚═╝  ╚═══╝╚═╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   */
  {
    name: "Midnight",
    shadows: softShadows,
    palette: {
      mode: "dark",
      background: {
        default: "#2b2b2b",
        paper: "#161616",
      },
      primary: {
        main: amber[200],
      },
      secondary: {
        main: indigo[200],
      },
      text: {
        primary: "#fff",
        deemphasized: grey[300],
      },
      error: {
        main: red[300],
      },
      nav: {
        main: "#212121",
        light: lighten("#212121", 0.1),
        dark: darken("#212121", 0.1),
        contrastText: "#fff",
        contrastTextSecondary: "#c0c0c0",
      },
      chart: [
        amber[200],
        indigo[300],
        red[400],
        green[300],
        pink[200],
        cyan[300],
        orange[400],
        blue[400],
      ],
    },
    typography: openSansTypography,
    components: components,
  },
  /*██████╗ ██╗   ██╗███╗   ██╗██╗  ██╗
    ██╔══██╗██║   ██║████╗  ██║██║ ██╔╝
    ██████╔╝██║   ██║██╔██╗ ██║█████╔╝ 
    ██╔═══╝ ██║   ██║██║╚██╗██║██╔═██╗ 
    ██║     ╚██████╔╝██║ ╚████║██║  ██╗
    ╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚═╝  ╚═╝*/
  {
    name: "Punk",
    shadows: softShadows,
    palette: {
      mode: "dark",
      background: {
        default: "#282a33",
        paper: "#11121c",
      },
      primary: {
        main: "#ffa7a1",
      },
      secondary: {
        main: "#bd80ff",
      },
      text: {
        primary: "#fff",
        deemphasized: grey[300],
      },
      nav: {
        main: "#171924",
        light: lighten("#171924", 0.1),
        dark: darken("#171924", 0.1),
        contrastText: "#fff",
        contrastTextSecondary: "#c0c0c0",
      },
      chart: [
        "#ffa7a1",
        deepPurple[300],
        green[300],
        amber[200],
        blue[400],
        orange[400],
        red[400],
        cyan[200],
      ],
    },
    typography: lacquerTypography,
    components: components,
  },
  /*███████╗██╗   ██╗███╗   ██╗████████╗██╗  ██╗
    ██╔════╝╚██╗ ██╔╝████╗  ██║╚══██╔══╝██║  ██║
    ███████╗ ╚████╔╝ ██╔██╗ ██║   ██║   ███████║
    ╚════██║  ╚██╔╝  ██║╚██╗██║   ██║   ██╔══██║
    ███████║   ██║   ██║ ╚████║   ██║   ██║  ██║
    ╚══════╝   ╚═╝   ╚═╝  ╚═══╝   ╚═╝   ╚═╝  ╚═╝*/
  {
    name: "Synth",
    shadows: [
      "none",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2),0 0 26px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2),0 0 26px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2),0 0 26px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2),0 0 26px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2),0 0 26px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2),0 0 26px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2),0 0 26px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2),0 0 26px rgba(255, 59, 144, 0.2),0 0 50px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2),0 0 26px rgba(255, 59, 144, 0.2),0 0 60px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2),0 0 26px rgba(255, 59, 144, 0.2),0 0 70px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2),0 0 26px rgba(255, 59, 144, 0.2),0 0 80px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2),0 0 26px rgba(255, 59, 144, 0.2),0 0 80px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2),0 0 26px rgba(255, 59, 144, 0.2),0 0 80px rgba(255, 59, 144, 0.2)",
      "0 0 3px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 59, 144, 0.2),0 0 16px rgba(255, 59, 144, 0.2),0 0 18px rgba(255, 59, 144, 0.2),0 0 22px rgba(255, 59, 144, 0.2),0 0 26px rgba(255, 59, 144, 0.2),0 0 80px rgba(255, 59, 144, 0.2)",
    ],
    palette: {
      mode: "dark",
      background: {
        default: "#180821",
        paper: "#180821",
      },
      primary: {
        main: "#ff426b",
      },
      secondary: {
        main: "#d15b00",
      },
      text: {
        primary: "#fff",
        deemphasized: grey[300],
      },
      nav: {
        main: "#20142b",
        light: lighten("#20142b", 0.1),
        dark: darken("#20142b", 0.1),
        contrastText: "#ffcfcc",
        contrastTextSecondary: "#fff37d",
      },
      chart: [
        "#ff426b",
        orange[300],
        cyan[300],
        amber[200],
        blue[400],
        orange[400],
        red[400],
        cyan[200],
      ],
    },
    typography: openSansTypography,
    components: components,
  },
  /*███████╗███████╗ █████╗ ██╗  ██╗ █████╗ ██╗    ██╗██╗  ██╗███████╗
    ██╔════╝██╔════╝██╔══██╗██║  ██║██╔══██╗██║    ██║██║ ██╔╝██╔════╝
    ███████╗█████╗  ███████║███████║███████║██║ █╗ ██║█████╔╝ ███████╗
    ╚════██║██╔══╝  ██╔══██║██╔══██║██╔══██║██║███╗██║██╔═██╗ ╚════██║
    ███████║███████╗██║  ██║██║  ██║██║  ██║╚███╔███╔╝██║  ██╗███████║
    ╚══════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚══╝╚══╝ ╚═╝  ╚═╝╚══════╝*/
  {
    name: "Seahawks",
    shadows: softShadows,
    palette: {
      mode: "light",
      background: {
        default: "#f2f3f7",
        paper: "#fff",
      },
      primary: {
        main: "#509e13",
      },
      secondary: {
        main: "#002f82",
      },
      text: {
        primary: grey[900],
        deemphasized: grey[300],
      },
      nav: {
        main: "#002245",
        light: "#042f5c",
        dark: "#001933",
        contrastText: "#fff",
        contrastTextSecondary: "#c0c0c0",
      },
      chart: [
        "#002245",
        "#509e13",
        red[600],
        purple[500],
        cyan[600],
        orange[900],
        blue[500],
      ],
    },
    typography: overpassTypography,
    components: components,
  },
];
