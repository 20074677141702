import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost } from "../apiService";

export function useGetProgramsList(statuses) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `programs/GetProgramsList/${statuses}`;
  const query = useQuery(url, ({ signal }) => AppsGet(token, url, signal), {
    enabled: statuses != null && statuses !== "",
  });
  return query;
}

export function useGetProgramDetailsPage(programId) {
  const token = GetAccessToken();
  const url = `programs/GetProgramDetailsPage/${programId}`;
  const query = useQuery(
    url,
    ({ signal }) => {
      if (programId == null || programId === "") return Promise.resolve();

      return AppsGet(token, url, signal);
    },
    {
      enabled: programId != null && programId !== "",
    }
  );
  return query;
}

export function useGetProgramDetails(programId) {
  const token = GetAccessToken();
  const url = `programs/GetProgramDetails/${programId}`;
  const query = useQuery(url, ({ signal }) => AppsGet(token, url, signal), {
    enabled: programId != null && programId !== "",
  });
  return query;
}

export function useGetProgramGuardianEmails(programId) {
  const token = GetAccessToken();
  const url = `programs/GetProgramGuardianEmails/${programId}`;
  const query = useQuery(url, ({ signal }) => AppsGet(token, url, signal), {
    enabled: programId != null && programId !== "",
  });
  return query;
}

export function useUpsertProgram() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "programs/UpsertProgram";
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (program) => {
      await AppsPost(token, url, program);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries((key) =>
          key.startsWith("programs")
        );
        snackbar.enqueueSnackbar(`Program saved`, {
          variant: "success",
        });
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error saving the program. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return mutation;
}
