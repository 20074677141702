import { Grid } from "@mui/material";
import { useOutletContext } from "react-router";
import TableDiscipline from "./TableDiscipline";

export default function InstructorReportsDiscipline() {
  const [slicers] = useOutletContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableDiscipline slicers={slicers} />
      </Grid>
    </Grid>
  );
}
