import { Paper, Typography, useTheme, Link } from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function HomeHowWeGotStarted() {
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  return (
    <Paper sx={{ ...commonStyles.paperPadding, mt: theme.spacing(2) }}>
      <Typography
        variant="h6"
        component="h2"
        color={theme.palette.text.secondary}
      >
        How we got started
      </Typography>
      <Typography variant="body1" component="p">
        Tacoma Public Schools and Greater Tacoma Community Foundation joined
        forces as part of a national initiative funded by The Wallace Foundation
        to implement an expanded social and emotional learning (SEL) program for
        elementary school children in Tacoma. Over the course of a four-year
        Partnerships for Social and Emotional Learning Initiative, the focus was
        on fostering positive social and emotional skills like self-control,
        teamwork, and goal-setting for approximately 15,000 children in
        kindergarten through fifth grade. This initiative spanned seven pilot
        schools in Tacoma, with the RAND Corporation conducting independent
        research to evaluate its benefits and identify potential challenges.
        Aligned with the Tacoma Whole Child Initiative, a decade-long
        partnership with the University of Washington Tacoma, the goal was to
        establish a city-wide approach to SEL. Implementation grants were
        contingent on successful negotiations last summer, accompanied by
        non-monetary support such as inclusion in a professional learning
        community and access to continuous improvement systems. The initiative
        built upon The Wallace Foundation's extensive track record in youth
        development and citywide coordination for afterschool programs.
      </Typography>
      <Typography variant="body2" component="p" sx={{ pt: theme.spacing(2) }}>
        For more information please visit{" "}
        <Link
          target="_blank"
          href="https://www.gtcf.org/news-reports/tacoma-public-schools-greater-tacoma-community-foundation-pilot-expanded-social-emotional-learning-support-wallace-foundation-grant/"
        >
          Greater Tacoma Community Foundation{" "}
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </Link>{" "}
        and{" "}
        <Link
          target="_blank"
          href="https://www.tacomaschools.org/departments/tacoma-whole-child"
          sx={{ pt: theme.spacing(2) }}
        >
          Tacoma Whole Child Initiative{" "}
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </Link>
      </Typography>
    </Paper>
  );
}
