import { Box, Button, Grid, Paper, useTheme, darken } from "@mui/material";
import { useState } from "react";
import PartnerLogoFull from "../images/PartnerLogoFull";
import TpsLogo from "../images/TpsLogo";
import React from "react";
import { loginRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { ScrollToTopOnMount } from "../../services/utilities";

import Rainier from "../images/Rainier";
import About from "../common/About";
import { useLocation, useNavigate } from "react-router";

import MicrosoftLogo from "../images/MicrosoftLogo";

export default function Login() {
  const { instance } = useMsal();
  const theme = useTheme();
  const [aboutOpen, setAboutOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  //Styles
  const centerChildrenStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
  };
  const panelStyles = {
    backgroundColor: theme.palette.nav?.main,
    display: "flex",
    maxWidth: 500,
    padding: theme.spacing(4),
    m: theme.spacing(1),
  };
  const signInButtonStyles = {
    fontFamily: "Segoe UI",
    textTransform: "none",
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: "0",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: darken(theme.palette.background.default, 0.2),
    },
  };

  return (
    <>
      <Rainier />
      <ScrollToTopOnMount />

      <About
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
        }}
      />

      <Box sx={centerChildrenStyles}>
        <Paper sx={panelStyles}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={9}>
              <PartnerLogoFull />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TpsLogo width={100} height={31.5} />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<MicrosoftLogo />}
                sx={signInButtonStyles}
                onClick={() => {
                  instance
                    .loginRedirect(loginRequest)
                    // .then((e) => {
                    //   // setLoginObject(e);
                    //   navigate(location?.pathname ?? "");
                    // })
                    .catch((e) => {
                      console.error(e);
                    });
                }}
              >
                Sign in
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  setAboutOpen(true);
                }}
              >
                About
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}
