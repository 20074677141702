import { useQuery, useQueries, useInfiniteQuery } from "react-query";
import { GetAccessToken, AppsPost, AppsGet } from "../apiService";


export function useGetProgramReport(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetProgramReport`;
  const query = useQuery(url + JSON.stringify(slicers), async ({ signal }) => {
    return await AppsPost(token, url, slicers, signal);
  });
  return query;
}

export function useGetReportSummary(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetReportSummary`;
  const query = useQuery(url + JSON.stringify(slicers), async ({ signal }) => {
    return await AppsPost(token, url, slicers, signal);
  });
  return query;
}

export function useProgramAttendance(slicers, rows) {
  const token = GetAccessToken();
  const url = `partnerReports/ProgramAttendance`;

  const fetchMore = async (pageParam, signal) => {
    return await AppsPost(
      token,
      `${url}?page=${Object.keys(pageParam).length === 0 ? 1 : pageParam}&rows=${rows}`,
      slicers,
      signal
    );
  };

  const attendanceQuery = useInfiniteQuery(
    url,
    ({ pageParam = 1, signal }) => fetchMore(pageParam, signal),
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.data.length < rows ? undefined : allPages.length + 1;
      },
    }
  );
  return attendanceQuery;
}

export function useGetLocationCounts(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetLocationCounts`;
  const query = useQuery(
    url + JSON.stringify(slicers),
    async ({ signal }) => {
      return await AppsPost(token, url, slicers, signal);
    },
    {
      keepPreviousData: true,
    }
  );
  return query;
}

export function useGetActivityCounts(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetActivityCounts`;
  const query = useQuery(
    url + JSON.stringify(slicers),
    async ({ signal }) => {
      return await AppsPost(token, url, slicers, signal);
    },
    {
      keepPreviousData: true,
    }
  );
  return query;
}

export function useGetTopActivityParticipationCounts(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetTopActivityParticipationCounts`;
  const query = useQuery(
    url + JSON.stringify(slicers),
    async ({ signal }) => {
      return await AppsPost(token, url, slicers, signal);
    },
    {
      keepPreviousData: true,
    }
  );
  return query;
}

export function useGetActivityTypeCounts(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetActivityTypeCounts`;
  const query = useQuery(
    url + JSON.stringify(slicers),
    async ({ signal }) => {
      return await AppsPost(token, url, slicers, signal);
    },
    {
      keepPreviousData: true,
    }
  );
  return query;
}

export function useGetProviderCounts(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetProviderCounts`;
  const query = useQuery(
    url + JSON.stringify(slicers),
    async ({ signal }) => {
      return await AppsPost(token, url, slicers, signal);
    },
    {
      keepPreviousData: true,
    }
  );
  return query;
}

export function useGetGradeCounts(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetGradeCounts`;
  const query = useQuery(
    url + JSON.stringify(slicers),
    async ({ signal }) => {
      return await AppsPost(token, url, slicers, signal);
    },
    {
      keepPreviousData: true,
    }
  );
  return query;
}

export function useGetStudentProgramCounts(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetStudentProgramCounts`;
  const query = useQuery(
    url + JSON.stringify(slicers),
    async ({ signal }) => {
      return await AppsPost(token, url, slicers, signal);
    },
    {
      keepPreviousData: true,
    }
  );
  return query;
}

export function useGetRaceGenderCounts(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetRaceGenderCounts`;
  const query = useQuery(
    url + JSON.stringify(slicers),
    async ({ signal }) => {
      return await AppsPost(token, url, slicers, signal);
    },
    {
      keepPreviousData: true,
    }
  );
  return query;
}

export function useGetTransportationCounts(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetTransportationCounts`;
  const query = useQuery(
    url + JSON.stringify(slicers),
    async ({ signal }) => {
      return await AppsPost(token, url, slicers, signal);
    },
    {
      keepPreviousData: true,
    }
  );
  return query;
}

export function useGetAssessmentData(slicers, totalRows) {
  const token = GetAccessToken();
  const pageSize = 10000;
  let totalPages = Math.ceil(totalRows / pageSize);
  const pageArray = Array.from({length: totalPages}, (v, k) => k+1);
  const queryArray = pageArray.map(page => {
    return {
      queryKey: [`partnerReports/GetAssessmentData/${page}/${pageSize}/${slicers}/${totalRows}`],
      queryFn: async ({ signal }) => {
        return await AppsPost(token, `partnerReports/GetAssessmentData/${page}/${pageSize}`, slicers, signal);
      },
      retry: 10,
      retryDelay: attempt => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      enabled: totalRows > 0,
    }
  });

  const queries = useQueries(queryArray);
  const isQueriesDone = queries.every(query => query.isLoading === false);
  const successfulQueries = queries.filter(query => query.isSuccess)?.length;
  const isErrors = queries?.length > 0 && queries.some(query => query.isError);
  totalPages = totalPages == 0 ? 1 : totalPages;
  const percentComplete = Math.floor((successfulQueries/totalPages) * 100);

  if (!isQueriesDone) {
    return {queries: [], percentComplete: percentComplete, finished: false, errors: isErrors};
  }
  return {queries: isErrors ? [] : queries, percentComplete: percentComplete, finished: queries.length > 0 && true, errors: isErrors};
}

export function useGetAssessmentDataTotalRowCount(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetAssessmentDataTotalRowCount`;
  const query = useQuery(url + JSON.stringify(slicers), async ({ signal }) => {
    return await AppsPost(token, url, slicers, signal);
  });
  return query;
}

export function useGetAttendanceData(slicers, totalRows) {
  const token = GetAccessToken();
  const pageSize = 10000;
  let totalPages = Math.ceil(totalRows / pageSize);
  const pageArray = Array.from({length: totalPages}, (v, k) => k+1);
  const queryArray = pageArray.map(page => {
    return {
      queryKey: [`partnerReports/GetAttendanceData/${page}/${pageSize}/${slicers}/${totalRows}`],
      queryFn: async ({ signal }) => {
        return await AppsPost(token, `partnerReports/GetAttendanceData/${page}/${pageSize}`, slicers, signal);
      },
      retry: 10,
      retryDelay: attempt => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      enabled: totalRows > 0,
    }
  });

  const queries = useQueries(queryArray);
  const isQueriesDone = queries.every(query => query.isLoading === false);
  const successfulQueries = queries.filter(query => query.isSuccess)?.length;
  const isErrors = queries?.length > 0 && queries.some(query => query.isError);
  totalPages = totalPages == 0 ? 1 : totalPages;
  const percentComplete = Math.floor((successfulQueries/totalPages) * 100);

  if (!isQueriesDone) {
    return {queries: [], percentComplete: percentComplete, finished: false, errors: isErrors};
  }
  return {queries: isErrors ? [] : queries, percentComplete: percentComplete, finished: queries.length > 0 && true, errors: isErrors};
}

export function useGetAttendanceDataTotalRowCount(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetAttendanceDataTotalRowCount`;
  const query = useQuery(url + JSON.stringify(slicers), async ({ signal }) => {
    return await AppsPost(token, url, slicers, signal);
  });
  return query;
}

export function useGetDemographicData(slicers, totalRows) {
  const token = GetAccessToken();
  const pageSize = 10000;
  let totalPages = Math.ceil(totalRows / pageSize);
  const pageArray = Array.from({length: totalPages}, (v, k) => k+1);
  const queryArray = pageArray.map(page => {
    return {
      queryKey: [`partnerReports/GetDemographicData/${page}/${pageSize}/${slicers}/${totalRows}`],
      queryFn: async ({ signal }) => {
        return await AppsPost(token, `partnerReports/GetDemographicData/${page}/${pageSize}`, slicers, signal);
      },
      retry: 10,
      retryDelay: attempt => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      enabled: totalRows > 0,
    }
  });

  const queries = useQueries(queryArray);
  const isQueriesDone = queries.every(query => query.isLoading === false);
  const successfulQueries = queries.filter(query => query.isSuccess)?.length;
  const isErrors = queries?.length > 0 && queries.some(query => query.isError);
  totalPages = totalPages == 0 ? 1 : totalPages;
  const percentComplete = Math.floor((successfulQueries/totalPages) * 100);

  if (!isQueriesDone) {
    return {queries: [], percentComplete: percentComplete, finished: false, errors: isErrors};
  }
  return {queries: isErrors ? [] : queries, percentComplete: percentComplete, finished: queries.length > 0 && true, errors: isErrors};
}

export function useGetDemographicDataTotalRowCount(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetDemographicDataTotalRowCount`;
  const query = useQuery(url + JSON.stringify(slicers), async ({ signal }) => {
    return await AppsPost(token, url, slicers, signal);
  });
  return query;
}

export function useGetDisciplineData(slicers, totalRows) {
  const token = GetAccessToken();
  const pageSize = 10000;
  let totalPages = Math.ceil(totalRows / pageSize);
  const pageArray = Array.from({length: totalPages}, (v, k) => k+1);
  const queryArray = pageArray.map(page => {
    return {
      queryKey: [`partnerReports/GetDisciplineData/${page}/${pageSize}/${slicers}/${totalRows}`],
      queryFn: async ({ signal }) => {
        return await AppsPost(token, `partnerReports/GetDisciplineData/${page}/${pageSize}`, slicers, signal);
      },
      retry: 10,
      retryDelay: attempt => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      enabled: totalRows > 0,
    }
  });

  const queries = useQueries(queryArray);
  const isQueriesDone = queries.every(query => query.isLoading === false);
  const successfulQueries = queries.filter(query => query.isSuccess)?.length;
  const isErrors = queries?.length > 0 && queries.some(query => query.isError);
  totalPages = totalPages == 0 ? 1 : totalPages;
  const percentComplete = Math.floor((successfulQueries/totalPages) * 100);

  if (!isQueriesDone) {
    return {queries: [], percentComplete: percentComplete, finished: false, errors: isErrors};
  }
  return {queries: isErrors ? [] : queries, percentComplete: percentComplete, finished: queries.length > 0 && true, errors: isErrors};
}

export function useGetDisciplineDataTotalRowCount(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetDisciplineDataTotalRowCount`;
  const query = useQuery(url + JSON.stringify(slicers), async ({ signal }) => {
    return await AppsPost(token, url, slicers, signal);
  });
  return query;
}

export function useGetEnrollmentData(slicers, totalRows) {
  const token = GetAccessToken();
  const pageSize = 10000;
  let totalPages = Math.ceil(totalRows / pageSize);
  const pageArray = Array.from({length: totalPages}, (v, k) => k+1);
  const queryArray = pageArray.map(page => {
    return {
      queryKey: [`partnerReports/GetEnrollmentData/${page}/${pageSize}/${slicers}/${totalRows}`],
      queryFn: async ({ signal }) => {
        return await AppsPost(token, `partnerReports/GetEnrollmentData/${page}/${pageSize}`, slicers, signal);
      },
      retry: 10,
      retryDelay: attempt => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      enabled: totalRows > 0,
    }
  });

  const queries = useQueries(queryArray);
  const isQueriesDone = queries.every(query => query.isLoading === false);
  const successfulQueries = queries.filter(query => query.isSuccess)?.length;
  const isErrors = queries?.length > 0 && queries.some(query => query.isError);
  totalPages = totalPages == 0 ? 1 : totalPages;
  const percentComplete = Math.floor((successfulQueries/totalPages) * 100);

  if (!isQueriesDone) {
    return {queries: [], percentComplete: percentComplete, finished: false, errors: isErrors};
  }
  return {queries: isErrors ? [] : queries, percentComplete: percentComplete, finished: queries.length > 0 && true, errors: isErrors};
}

export function useGetEnrollmentDataTotalRowCount(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetEnrollmentDataTotalRowCount`;
  const query = useQuery(url + JSON.stringify(slicers), async ({ signal }) => {
    return await AppsPost(token, url, slicers, signal);
  });
  return query;
}

export function useGetMarkData(slicers, totalRows) {
  const token = GetAccessToken();
  const pageSize = 10000;
  let totalPages = Math.ceil(totalRows / pageSize);
  const pageArray = Array.from({length: totalPages}, (v, k) => k+1);
  const queryArray = pageArray.map(page => {
    return {
      queryKey: [`partnerReports/GetMarkData/${page}/${pageSize}/${slicers}/${totalRows}`],
      queryFn: async ({ signal }) => {
        return await AppsPost(token, `partnerReports/GetMarkData/${page}/${pageSize}`, slicers, signal);
      },
      retry: 10,
      retryDelay: attempt => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      enabled: totalRows > 0,
    }
  });
  
  const queries = useQueries(queryArray);
  const isQueriesDone = queries.every(query => query.isLoading === false);
  const successfulQueries = queries.filter(query => query.isSuccess)?.length;
  const isErrors = queries?.length > 0 && queries.some(query => query.isError);
  totalPages = totalPages == 0 ? 1 : totalPages;
  const percentComplete = Math.floor((successfulQueries/totalPages) * 100);

  if (!isQueriesDone) {
    return {queries: [], percentComplete: percentComplete, finished: false, errors: isErrors};
  }
  return {queries: isErrors ? [] : queries, percentComplete: percentComplete, finished: queries.length > 0 && true, errors: isErrors};
}

export function useGetMarkDataTotalRowCount(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetMarkDataTotalRowCount`;
  const query = useQuery(url + JSON.stringify(slicers), async ({ signal }) => {
    return await AppsPost(token, url, slicers, signal);
  });
  return query;
}

//Slicers
export function useGetSlicers() {
  const token = GetAccessToken();
  const url = `partnerReports/GetSlicers`;
  const query = useQuery(url, async ({ signal }) => {
    return await AppsGet(token, url, signal);
  });
  return query;
}

export function useGetProvidersSlicer(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetProvidersSlicer`;
  var slicersCopy = { ...slicers };
  slicersCopy.providerIds = [];
  const query = useQuery(
    url + JSON.stringify(slicersCopy),
    async ({ signal }) => {
      return await AppsPost(token, url, slicersCopy, signal);
    }
  );
  return query;
}

export function useGetSchoolYearsSlicer(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetSchoolYearsSlicer`;
  var slicersCopy = { ...slicers };
  slicersCopy.schoolYears = [];
  const query = useQuery(
    url + JSON.stringify(slicersCopy),
    async ({ signal }) => {
      return await AppsPost(token, url, slicersCopy, signal);
    }
  );
  return query;
}

export function useGetSessionsSlicer(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetSessionsSlicer`;
  var slicersCopy = { ...slicers };
  slicersCopy.sessionIds = [];
  const query = useQuery(
    url + JSON.stringify(slicersCopy),
    async ({ signal }) => {
      return await AppsPost(token, url, slicersCopy, signal);
    }
  );
  return query;
}

export function useGetLocationsSlicer(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetLocationsSlicer`;
  var slicersCopy = { ...slicers };
  slicersCopy.locationIds = [];
  const query = useQuery(
    url + JSON.stringify(slicersCopy),
    async ({ signal }) => {
      return await AppsPost(token, url, slicersCopy, signal);
    }
  );
  return query;
}

export function useGetActivitiesSlicer(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetActivitiesSlicer`;
  var slicersCopy = { ...slicers };
  slicersCopy.activityIds = [];
  const query = useQuery(
    url + JSON.stringify(slicersCopy),
    async ({ signal }) => {
      return await AppsPost(token, url, slicersCopy, signal);
    }
  );
  return query;
}

export function useGetGradeSlicers(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetGradeSlicers`;
  var slicersCopy = { ...slicers };
  slicersCopy.grades = [];
  const query = useQuery(
    url + JSON.stringify(slicersCopy),
    async ({ signal }) => {
      return await AppsPost(token, url, slicersCopy, signal);
    }
  );
  return query;
}

export function useGetStudentProgramsSlicer(slicers) {
  // const token = GetAccessToken();
  // const url = `partnerReports/GetStudentProgramsSlicer`;
  // var slicersCopy = { ...slicers };
  // slicersCopy.studentPrograms = [];
  // const query = useQuery(
  //   url + JSON.stringify(slicersCopy),
  //   async ({ signal }) => {
  //     return await AppsPost(token, url, slicersCopy, signal);
  //   }
  // );
  // return query;
  // Hiding Student Programs data until we can ensure accuracy, but leaving the plumbing in place
  return [];
}

export function useGetGendersSlicer(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetGendersSlicer`;
  var slicersCopy = { ...slicers };
  slicersCopy.genders = [];
  const query = useQuery(
    url + JSON.stringify(slicersCopy),
    async ({ signal }) => {
      return await AppsPost(token, url, slicersCopy, signal);
    }
  );
  return query;
}

export function useGetRacesSlicer(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetRacesSlicer`;
  var slicersCopy = { ...slicers };
  slicersCopy.races = [];
  const query = useQuery(
    url + JSON.stringify(slicersCopy),
    async ({ signal }) => {
      return await AppsPost(token, url, slicersCopy, signal);
    }
  );
  return query;
}

export function useGetTransportationsSlicer(slicers) {
  const token = GetAccessToken();
  const url = `partnerReports/GetTransportationsSlicer`;
  var slicersCopy = { ...slicers };
  slicersCopy.transportationIds = [];
  const query = useQuery(
    url + JSON.stringify(slicersCopy),
    async ({ signal }) => {
      return await AppsPost(token, url, slicersCopy, signal);
    }
  );
  return query;
}
