import { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Error from "../common/Error";
import {
  useGetEligibleStudents,
  useGetParticipant,
  usePostParticipant,
  usePutParticipant,
} from "../../services/hooks/participantsHook";
import { useGetTransportations } from "../../services/hooks/transportationsHook";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import { getGradeName } from "../../services/utilities";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers/";
import { useEffect } from "react";

export default function ParticipantRegistration(props) {
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const { programId, participantId, open, onClose, programDetails } = props;

  const [studentId, setStudentId] = useState();
  const [entryDate, setEntryDate] = useState(dayjs().format("MM/DD/YYYY"));
  const [withdrawalDate, setWithdrawalDate] = useState();
  const [transportationId, setTransportationId] = useState();
  const [studentGrade, setStudentGrade] = useState();

  const participantQuery = useGetParticipant(participantId);
  const participantData = participantQuery?.data?.data;

  useEffect(() => {
    if (participantData != null) {
      setStudentId(participantData.studentId);
      setTransportationId(participantData?.transportationId);
      setEntryDate(participantData?.entryDate);
      setWithdrawalDate(participantData?.withdrawalDate);
      setStudentGrade(participantData?.grade);
    }
  }, [participantData]);

  const transportationsQuery = useGetTransportations();
  const transportations = transportationsQuery?.data?.data;

  const eligibleStudentsQuery = useGetEligibleStudents(programId, open);
  const eligibleStudents = eligibleStudentsQuery?.data?.data;

  const participantPostBody = {
    ParticipantId: participantId ?? 0,
    ProgramID: programId,
    StudentID: studentId,
    EntryDate: entryDate,
    WithdrawalDate: withdrawalDate,
    TransportationID: transportationId,
    Grade: studentGrade,
    createdBy: participantData?.createdBy,
    createdDate: participantData?.createdDate,
    modifiedBy: participantData?.modifiedBy,
    modifiedDate: participantData?.modifiedDate,
  };

  const postParticipant = usePostParticipant(participantPostBody);
  const putParticipant = usePutParticipant(participantPostBody);

  const formDisabled =
    postParticipant?.isLoading ||
    participantQuery?.isLoading ||
    participantQuery?.isError ||
    transportationsQuery?.isLoading ||
    transportationsQuery?.isError;

  const enrollStudent = () => {
    //PUT
    if (participantId != null) {
      putParticipant.mutateAsync().then((res) => {
        close();
      });
    }
    //POST
    else if (studentId && entryDate) {
      postParticipant.mutateAsync().then((res) => {
        close();
      });
    }
  };

  const close = () => {
    setStudentId(null);
    setEntryDate(dayjs().format("MM/DD/YYYY"));
    setWithdrawalDate(null);
    setTransportationId(null);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth="sm"
      sx={{ "& .MuiPaper-root": { overflowY: "visible" } }}
    >
      <DialogTitle>Student Registration</DialogTitle>
      <Box sx={[commonStyles.paperPadding]}>
        <Typography sx={{ color: theme.palette.text.secondary }}>
          The students available for registration are limited to active students
        </Typography>
        <br />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Error
              message="There was an issue loading eligible students."
              query={eligibleStudentsQuery}
            />

            <Autocomplete
              disabled={formDisabled || participantId != null}
              loading={eligibleStudentsQuery?.isLoading}
              disablePortal
              options={eligibleStudents ?? []}
              getOptionDisabled={(option) => option?.enrolled ?? false}
              getOptionLabel={(option) =>
                `${option.lastName}, ${option.firstName} (${
                  option.studentId
                }) - ${option.school} - ${getGradeName(option.grade)}`
              }
              value={
                eligibleStudents?.find((s) => s.studentId === studentId) ?? null
              }
              onChange={(event, value) => {
                setStudentId(value?.studentId);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Student" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {(programDetails?.enableTransportation ||
              participantData?.transportationId != null) && (
              <Autocomplete
                disabled={formDisabled}
                loading={transportationsQuery?.isLoading}
                disablePortal
                options={transportations}
                getOptionLabel={(option) => option.method}
                value={
                  transportations?.find(
                    (t) => t.transportationId === transportationId
                  ) ?? null
                }
                onChange={(event, value) => {
                  setTransportationId(value?.transportationId);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Transportation" />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={formDisabled}
                label="Registration Start"
                format="MM/DD/YY"
                value={dayjs(entryDate)}
                defaultValue={dayjs().format("MM/DD/YY")}
                onChange={(value) => {
                  setEntryDate(value);
                }}
                slotProps={{
                  textField: {
                    required: true,
                    fullWidth: true,
                    label: "Enroll Date",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button
          variant="contained"
          disabled={formDisabled || studentId == null || entryDate == null}
          onClick={() => {
            enrollStudent();
          }}
          endIcon={postParticipant?.isLoading && <CircularProgress size={20} />}
        >
          Register
        </Button>
      </DialogActions>
    </Dialog>
  );
}
