import React, { useState, useEffect, useMemo } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import * as currency from "currency.js";
import Error from "../common/Error";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSnackbar } from "notistack";
import { faSave, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { DayPicker } from "react-day-picker";
import { useTheme } from "@mui/material/styles";

import {
  formatDate,
  formatTime,
  getSchoolYear,
  getGradeName,
  ScrollToTopOnMount,
} from "../../services/utilities";
import { useUser } from "../../services/contexts/userContext";
import { useGetActivities } from "../../services/hooks/activitiesHook";
import {
  useGetProgramDetails,
  useUpsertProgram,
} from "../../services/hooks/programsHook";
import { useGetCycles } from "../../services/hooks/cyclesHook";
import { useGetSessions } from "../../services/hooks/sessionsHook";
import { useGetSchools } from "../../services/hooks/schoolsHook";
import { useGetLocations } from "../../services/hooks/locationHook";
import { useGetGradesByLocationIds } from "../../services/hooks/gradesHook";
import {
  useGetProviders,
  useGetProviderLocationOffereds,
  useGetProviderActivityOffereds,
} from "../../services/hooks/providersHook";
import { useGetEligibleInstructors } from "../../services/hooks/personHook";
import { DraftPrograms, ManagePrograms } from "../../services/permissions";
import { useGetRestrictions } from "../../services/hooks/gradesHook copy";
import { MobileTimePicker } from "@mui/x-date-pickers";

export default function ProgramCru() {
  const { user } = useUser();
  const theme = useTheme();
  const snackbar = useSnackbar();
  const { programId } = useParams();
  const isEdit = programId != null;

  const navigate = useNavigate();

  //Get data
  const detailsQuery = useGetProgramDetails(programId);
  const details = useMemo(() => detailsQuery?.data?.data, [detailsQuery]);

  const userCanEdit =
    user?.permissions?.includes(ManagePrograms) ||
    (user?.permissions?.includes(DraftPrograms) &&
      (!isEdit || details?.isDraft === true));
  //Fields
  const [partnerId, setPartnerId] = useState();

  const [providerId, setProviderId] = useState();
  const [providerError, setProviderError] = useState();

  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState();

  const [description, setDescription] = useState("");

  const [activityId, setActivityId] = useState();
  const [activityError, setActivityError] = useState();

  const [selectedInstructors, setSelectedInstructors] = useState();
  const [instructorsError, setInstructorsError] = useState();

  const [sessionId, setSessionId] = useState();
  const [sessionError, setSessionError] = useState();

  const [selectedSchools, setSelectedSchools] = useState();
  const [schoolsError, setSchoolsError] = useState();

  const [selectedGrades, setSelectedGrades] = useState();
  const [gradesError, setGradesError] = useState();

  const [maxEnrollment, setMaxEnrollment] = useState(20);
  const [maxEnrollmentError, setMaxEnrollmentError] = useState();

  const [selectedRestrictions, setSelectedRestrictions] = useState();

  const [cycleId, setCycleId] = useState();
  const [cycleError, setCycleError] = useState();

  const [regStartDate, setRegStartDate] = useState(null);
  const [regStartDateError, setRegStartDateError] = useState();

  const [regEndDate, setRegEndDate] = useState(null);
  const [regEndDateError, setRegEndDateError] = useState();

  const [startDate, setStartDate] = useState(null);
  const [startDateError, setStartDateError] = useState();

  const [disabledDates, setDisabledDates] = useState([]);

  const [endDate, setEndDate] = useState(null);
  const [endDateError, setEndDateError] = useState();

  const [meetingUrl, setMeetingUrl] = useState();

  const [locationId, setLocationId] = useState();
  const [locationError, setLocationError] = useState();

  const [room, setRoom] = useState();
  const [roomError, setRoomError] = useState();

  const [defaultStartTime, setDefaultStartTime] = useState(null);

  const [defaultDurationHours, setDefaultDurationHours] = useState(0);
  const [defaultDurationError, setDefaultDurationError] = useState();

  const [cost, setCost] = useState(currency(0));
  const [costError, setCostError] = useState();

  const [advertiseDate, setAdvertiseDate] = useState(null);

  const [regularDays, setRegularDays] = useState([]);
  const [eventDays, setEventDays] = useState([]);
  const [programDaysError, setProgramDaysError] = useState("");
  // const [eventDaysError, setEventDaysError] = useState("");
  const programDays = useMemo(() => {
    return [...regularDays, ...eventDays]?.sort(
      (a, b) => new Date(a.scheduledDate) - new Date(b.scheduledDate)
    );
  }, [regularDays, eventDays]);

  const [teeShirtRequired, setTeeShirtRequired] = useState(false);
  const [fixedCost, setFixedCost] = useState(false);
  const [manualEnrollment, setManualEnrollment] = useState(false);
  const [hideInCompass, setHideInCompass] = useState(false);
  const [enableTransportation, setEnableTransportation] = useState(false);
  // const [hideCost, setHideCost] = useState(false);
  const [isDraft, setIsDraft] = useState(!userCanEdit);

  //Populate fields from details query
  useEffect(() => {
    if (isEdit && details != null) {
      setPartnerId(details.partnerId);
      setProviderId(details.providerId);
      setTitle(details.title);
      setActivityId(details.activityId);
      setDescription(details.description);
      setSelectedInstructors(
        details.programInstructors?.map((i) => i.personId)
      );
      setSelectedSchools(
        details.programLocationOffereds?.map((s) => s.locationId)
      );
      setSelectedGrades(details.programGrades?.map((g) => g.gradeId));
      setSessionId(details.sessionId);
      setMaxEnrollment(details.maxEnrollment);
      setSelectedRestrictions(
        details?.programRestrictions?.map((pr) => pr.restrictionId)
      );
      setCycleId(details.cycleId);
      setRegStartDate(details.registrationStartDate);
      setRegEndDate(details.registrationEndDate);
      setStartDate(details.startDate);
      setEndDate(details.endDate);
      setAdvertiseDate(details.advertiseDate);
      setMeetingUrl(details.url);
      setLocationId(details.locationId);
      setRoom(
        details?.programDays?.filter((pd) => pd.isEvent === false)?.length >
          0 &&
          details?.programDays?.filter((pd) => pd.isEvent === false)[0].room
      );
      setDefaultStartTime(timeToDayjs(details.defaultStartTime));
      setDefaultDurationHours(details.defaultDurationHours);
      setRegularDays(details.programDays?.filter((pd) => pd.isEvent === false));
      // setEventDays(
      //   details.programDays
      //     ?.filter((pd) => pd.isEvent == true)
      //     ?.map((pd) => {
      //       pd.startTime = timeToDayjs(pd.startTime);
      //       return pd;
      //     })
      // );
      setTeeShirtRequired(details.isTeeShirtRequired);
      setManualEnrollment(details.manualEnrollment);
      setHideInCompass(details.isHidden);
      setEnableTransportation(details.enableTransportation);
      // setHideCost(details.hideCost);
      setCost(currency(details?.cost) ?? currency(0));
      setFixedCost(details?.isFixedCost);
      setIsDraft(details?.participants?.length > 0 ? false : details.isDraft);
      setDisabledDates(details?.programAttendances);
    }
  }, [details, isEdit]);

  const providersQuery = useGetProviders();
  const providers = useMemo(
    () =>
      providersQuery?.data?.data?.filter(
        (p) => p.active || p.providerId === providerId
      ),
    [providersQuery, providerId]
  );

  const providerLocationsQuery = useGetProviderLocationOffereds(providerId);
  const providerLocations = useMemo(
    () => providerLocationsQuery?.data?.data?.filter((l) => l.active),
    [providerLocationsQuery]
  );
  const schoolsQuery = useGetSchools();
  const schools = useMemo(
    () => schoolsQuery?.data?.data?.filter((s) => s.active),
    [schoolsQuery]
  );

  const locationsQuery = useGetLocations();
  const locations = useMemo(
    () =>
      locationsQuery?.data?.data?.filter(
        (l) => l.active || locationId === l.locationId
      ),
    [locationsQuery, locationId, providerLocations]
  );

  const providerActivitiesQuery = useGetProviderActivityOffereds(providerId);
  const providerActivities = providerActivitiesQuery?.data?.data;
  const activitiesQuery = useGetActivities();
  const activities = useMemo(() => {
    return activitiesQuery?.data?.data
      ?.filter(
        (a) => a.active || a.activityId === activityId //or being used by this provider
      )
      ?.sort((a, b) => {
        if (
          a.activityType?.activityTypeName === b.activityType?.activityTypeName
        )
          return a.activityName?.localeCompare(b.activityName);
        return a.activityType?.activityTypeName?.localeCompare(
          b.activityType?.activityTypeName
        );
      });
  }, [activitiesQuery, activityId, providerActivities]);

  const eligibleInstructorsQuery = useGetEligibleInstructors(
    providerId,
    programId
  );
  const eligibleInstructors = eligibleInstructorsQuery?.data?.data;

  const sessionsQuery = useGetSessions();
  const sessions = useMemo(
    () => sessionsQuery?.data?.data?.filter((s) => s.active),
    [sessionsQuery]
  );

  const gradesQuery = useGetGradesByLocationIds(selectedSchools);
  const grades = useMemo(() => gradesQuery?.data?.data, [gradesQuery]);

  const cyclesQuery = useGetCycles();
  const cycles = useMemo(() => cyclesQuery?.data?.data, [cyclesQuery]);

  const restrictionsQuery = useGetRestrictions();
  const restrictions = useMemo(
    () => restrictionsQuery?.data?.data,
    [restrictionsQuery]
  );

  //Functions
  const dayOfWeekIndex = (dayName) => {
    let dayIndex;
    switch (dayName) {
      case "Monday":
        dayIndex = 1;
        break;
      case "Tuesday":
        dayIndex = 2;
        break;
      case "Wednesday":
        dayIndex = 3;
        break;
      case "Thursday":
        dayIndex = 4;
        break;
      case "Friday":
        dayIndex = 5;
        break;
      case "Saturday":
        dayIndex = 6;
        break;
      case "Sunday":
        dayIndex = 0;
        break;
      default:
        break;
    }
    return dayIndex;
  };
  //Populate programDays when parameters are populated
  useEffect(() => {
    if (
      !isEdit &&
      cycleId &&
      startDate &&
      endDate &&
      dayjs(startDate).isBefore(dayjs(endDate))
    ) {
      let currentDate = dayjs(startDate);
      let stopDate = dayjs(endDate);
      let daysOfWeek = cycles
        ?.find((c) => c.cycleId === cycleId)
        ?.cycleName?.split(", ")
        ?.map((dayName) => dayOfWeekIndex(dayName));

      //Fill dateArray with all dates between start and endDate
      let dateArray = [];
      while (currentDate.isBefore(stopDate)) {
        dateArray.push(dayjs(currentDate));
        currentDate = dayjs(currentDate).add(1, "day");
      }

      dateArray = dateArray?.filter((d) =>
        daysOfWeek?.includes(dayjs(d).day())
      );
      let pd = dateArray?.map((d) => {
        return {
          scheduledDate: formatDate(d),
          startTime:
            defaultStartTime && dayjs(defaultStartTime)?.format("HH:mm"),
          durationHours: defaultDurationHours,
          room: room,
          isEvent: false,
          programId: programId ?? 0,
        };
      });
      setRegularDays(pd);
      setProgramDaysError("");
    }
  }, [
    cycleId,
    startDate,
    endDate,
    cycles,
    defaultDurationHours,
    defaultStartTime,
    isEdit,
    programId,
    room,
  ]);

  //Update program days when startTime, room, or duration changes
  useEffect(() => {
    // setEventDaysError("");
    setProgramDaysError("");
    if (regularDays?.length > 0) {
      let copyDays = JSON.parse(JSON.stringify(regularDays));
      copyDays = copyDays?.map((d) => {
        d.scheduledDate = d.scheduledDate;
        d.startTime =
          defaultStartTime && dayjs(defaultStartTime)?.format("HH:mm");
        d.durationHours = defaultDurationHours;
        d.room = room;
        d.isEvent = false;
        return d;
      });
      setRegularDays(copyDays);
    }
  }, [room, defaultStartTime, defaultDurationHours, regularDays]);

  const getRandomTitlePlaceholder = () => {
    const titles = [
      "Searching for Bigfoot",
      "Deep Sea Diving",
      "Cooking with Explosions",
      "Snowman Engineering",
      "Sewer Exploration",
      "Lumberjack Racing",
      "Rock Painting",
      "Competitive Veganism",
      "Rocket Surgery",
      "Uphill Skiing",
    ];
    return titles[Math.floor(Math.random() * titles.length)];
  };

  // const [eventToRemove, setEventToRemove] = useState();
  // const updateEventDay = (index, property, val) => {
  //   let updatedEventDays = JSON.parse(JSON.stringify(eventDays));
  //   updatedEventDays[index][property] = val;
  //   setEventDays(updatedEventDays);
  //   setEventDaysError("");
  // };
  // const removeEventDay = (index) => {
  //   let updatedEventDays = JSON.parse(JSON.stringify(eventDays));
  //   updatedEventDays.splice(index, 1);
  //   setEventDays(updatedEventDays);
  //   setEventToRemove(null);
  //   setEventDaysError("");
  // };
  // const addNewEventDay = () => {
  //   let updatedEventDays = JSON.parse(JSON.stringify(eventDays));
  //   let programDateObj = {
  //     scheduledDate: dayjs(),
  //     startTime: defaultStartTime,
  //     durationHours: defaultDurationHours,
  //     isEvent: true,
  //     room: room,
  //     programId: programId ?? 0,
  //     programDayId: 0,
  //   };

  //   updatedEventDays.push(programDateObj);
  //   setEventDays(updatedEventDays);
  // };

  const AddRemoveRegularDay = (date) => {
    setProgramDaysError("");
    // setEventDaysError("");
    date = formatDate(date);
    let copyNonEventDays = JSON.parse(JSON.stringify(regularDays));
    let foundDateIndex = copyNonEventDays.findIndex(
      (d) => formatDate(d.scheduledDate) === date
    );

    //insert
    if (foundDateIndex === -1)
      copyNonEventDays.push({
        scheduledDate: date,
        startTime: defaultStartTime && dayjs(defaultStartTime).format("HH:mm"),
        durationHours: defaultDurationHours,
        room: room,
        isEvent: false,
        programId: programId ?? 0,
        programDayId: 0,
      });
    //remove
    else if (
      disabledDates?.some(
        (dd) =>
          dd.programDayId === copyNonEventDays[foundDateIndex].programDayId
      )
    ) {
      snackbar.enqueueSnackbar(
        `This day has attendance and cannot be removed.`,
        {
          variant: "error",
        }
      );
    } else {
      copyNonEventDays.splice(foundDateIndex, 1);
    }

    setRegularDays(copyNonEventDays);
  };

  const GetPostObject = () => {
    return {
      programId: programId,
      partnerId: partnerId,
      providerId: providerId,
      title: title,
      activityId: activityId,
      description: description,
      schoolYear: getSchoolYear(startDate),
      programInstructors: selectedInstructors?.map((i) => {
        return {
          programId: programId ?? 0,
          personId: i,
          programInstructorId: details?.programInstructors?.find(
            (pi) => pi.personId === i
          )?.programInstructorId,
        };
      }),
      programLocationOffereds: selectedSchools?.map((s) => {
        return {
          programId: details?.programLocationOffereds?.some(
            (plo) => plo.locationId === s
          )
            ? programId
            : 0,
          locationId: s,
        };
      }),
      programGrades: selectedGrades?.map((g) => {
        return {
          programId: details?.programGrades?.some((pg) => pg.gradeId === g)
            ? programId
            : 0,
          gradeId: g,
        };
      }),
      programRestrictions: selectedRestrictions?.map((r) => {
        return {
          programId: details?.programRestrictions?.some(
            (pr) => pr.restrictionId === r
          )
            ? programId
            : 0,
          restrictionId: r,
        };
      }),
      sessionId: sessionId,
      maxEnrollment: maxEnrollment,
      cycleId: cycleId,
      registrationStartDate: formatDate(regStartDate),
      registrationEndDate: formatDate(regEndDate),
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      advertiseDate: advertiseDate ? formatDate(advertiseDate) : null,
      url: meetingUrl,
      locationId: locationId,
      defaultStartTime: dayjs(defaultStartTime).format("HH:mm:ss"),
      defaultDurationHours: defaultDurationHours,
      programDays: programDays?.map((pd) => {
        // if (pd.isEvent) {
        //   pd.startTime = dayjs(pd.startTime).format("HH:mm");
        // }
        if (pd.startTime) {
          const timePartsCount = pd.startTime.split(":").length;
          if (timePartsCount === 2) {
            pd.startTime = `${pd.startTime}:00`;
          }
          //pd.startTime = dayjs(pd.startTime).format("HH:mm:ss");
        }
        pd.programAttendances = null;
        return pd;
      }),
      isTeeShirtRequired: teeShirtRequired,
      isFixedCost: fixedCost,
      manualEnrollment: manualEnrollment,
      // hideCost: hideCost,
      cost: currency(cost).value,
      isHidden: hideInCompass,
      enableTransportation: enableTransportation,
      isDraft:
        user?.permissions?.includes(DraftPrograms) &&
        !user?.permissions?.includes(ManagePrograms)
          ? true
          : isDraft,
      createdBy: details?.createdBy,
      createdDate: details?.createdDate,
      modifiedBy: details?.modifiedBy,
      modifiedDate: details?.modifiedDate,
    };
  };

  const upsertProgram = useUpsertProgram();

  const handleClickSave = () => {
    if (userCanEdit && ValidateForm()) {
      upsertProgram.mutateAsync(GetPostObject(false)).then((res) => {
        navigate("/programs");
      });
    }
  };

  const ValidateForm = () => {
    let valid = true;
    if (providerId == null) {
      setProviderError("A provider is required.");
      valid = false;
    }
    if ((title ?? "") === "") {
      setTitleError("A title is required.");
      valid = false;
    }
    if (activityId == null) {
      setActivityError("An activity is required.");
      valid = false;
    }
    if (selectedSchools == null || selectedSchools?.length === 0) {
      setSchoolsError("At least one school is required.");
      valid = false;
    }
    if (selectedGrades == null || selectedGrades?.length === 0) {
      setGradesError("At least one grade is required.");
      valid = false;
    }
    if (sessionId == null) {
      setSessionError("Session is required.");
      valid = false;
    }
    if (maxEnrollment == null) {
      setMaxEnrollmentError("Max enrollment must be specified.");
      valid = false;
    }
    if (cycleId == null) {
      setCycleError("Cycle is required.");
      valid = false;
    }
    if (regStartDate == null) {
      setRegStartDateError("A registration start date is required.");
      valid = false;
    }
    if (regEndDate == null) {
      setRegEndDateError("A registration end date is required.");
      valid = false;
    }
    if (dayjs(regStartDate).isAfter(dayjs(regEndDate))) {
      setRegEndDateError(
        "Registration end date must be on or after the registration start date."
      );
      valid = false;
    }
    if (startDate == null) {
      setStartDateError("A start date is required.");
      valid = false;
    }
    if (endDate == null) {
      setEndDateError("An end date is required.");
      valid = false;
    }
    if (dayjs(startDate).isAfter(endDate)) {
      setEndDateError("The end date must be on or after the start date.");
      valid = false;
    }
    if (locationId == null) {
      setLocationError("Location is required.");
      valid = false;
    }
    if (cost === null || cost === undefined) {
      setCostError("A cost is required.");
      valid = false;
    }
    if (isNaN(cost)) {
      setCostError("Cost must be a number.");
      valid = false;
    }
    if (cost < 0) {
      setCostError("Cost must be greater than or equal to 0.");
      valid = false;
    }
    // let dateTimes = programDays?.map((pd) => {
    //   return {
    //     scheduledDate: pd.scheduledDate,
    //     startTime: dayjs(pd.startTime).format("HH:mm"),
    //   };
    // });
    // let uniqueDateTimes = new Set(dateTimes);
    // if (dateTimes?.length != uniqueDateTimes?.size) {
    //   setEventDaysError("Two days cannot share the same date and time.");
    //   valid = false;
    // }
    return valid;
  };
  const formDisabled =
    !userCanEdit ||
    detailsQuery?.isLoading ||
    detailsQuery?.isError ||
    upsertProgram?.isLoading;

  const GetInstructorName = (personId) => {
    let foundPerson = eligibleInstructors?.find((l) => l.personId === personId);
    return `${foundPerson?.firstName} ${foundPerson?.lastName}`;
  };

  //Styles
  const calendarStyles = {
    // --rdp-cell-size: 40px;
    // --rdp-accent-color: #0000ff;
    // --rdp-background-color: #e7edff;
    // --rdp-accent-color-dark: #3003e1;
    // --rdp-background-color-dark: #180270;
    // --rdp-outline: 2px solid var(--rdp-accent-color);
    // --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);

    selected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  };

  const programDaysListStyles = {
    maxHeight: 350,
  };

  const timeToDayjs = (timeString) =>
    dayjs(`${formatDate(new Date())} ${timeString}`);

  return (
    <>
      <ScrollToTopOnMount />
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="h1">
            {isEdit ? "Edit" : "Create"} Program
          </Typography>
        </Grid>
        {detailsQuery?.isLoading && (
          <Grid item>
            <CircularProgress />
          </Grid>
        )}
      </Grid>
      <br />
      <Error
        message="There was a problem retrieving program details."
        query={detailsQuery}
      />
      <Grid container spacing={2}>
        {/* Provider */}
        {
          <Grid item xs={12}>
            <Error
              message="There was an issue loading providers."
              query={providersQuery}
            />
            <Autocomplete
              required
              disabled={providersQuery.isLoading || formDisabled}
              options={providers ?? []}
              value={
                providers?.find((p) => p.providerId === providerId) || null
              }
              onChange={(event, newValue) => {
                setProviderId(newValue?.providerId);
                setProviderError("");

                setActivityId(null);
                setActivityError("");

                setSelectedInstructors([]);

                setSelectedSchools([]);
                setSchoolsError("");

                setSelectedGrades([]);
                setGradesError("");

                setLocationId(null);
                setLocationError("");
              }}
              getOptionLabel={(option) => option?.name ?? ""}
              renderInput={(params) => (
                <TextField
                  required
                  error={!!providerError}
                  helperText={providerError}
                  {...params}
                  label="Provider"
                />
              )}
            />
          </Grid>
        }

        {/*title*/}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled}
              required
              fullWidth
              label="Title"
              variant="outlined"
              placeholder={getRandomTitlePlaceholder()}
              inputProps={{ maxLength: 100, autoComplete: "off" }}
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
                setTitleError(false);
              }}
              error={!!titleError}
              helperText={titleError}
            />
          </FormControl>
        </Grid>

        {/*activity*/}
        <Grid item xs={12} sm={6}>
          <Error
            message="There was an issue loading activities."
            query={activitiesQuery}
          />
          <Error
            message="There was an issue loading provider activities."
            query={providerActivitiesQuery}
          />
          <FormControl fullWidth>
            <Autocomplete
              disabled={
                formDisabled ||
                providerId == null ||
                activitiesQuery?.isLoading ||
                activitiesQuery?.isError ||
                providerActivities?.isLoading ||
                providerActivitiesQuery?.isError
              }
              disablePortal
              options={!!activities ? activities : []}
              getOptionLabel={(option) => option.activityName ?? ""}
              groupBy={(option) => option.activityType?.activityTypeName}
              value={
                activities?.find((a) => a.activityId === activityId) || null
              }
              onChange={(event, value) => {
                setActivityId(value?.activityId);
                setActivityError(false);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  error={!!activityError}
                  helperText={
                    providerId == null ? "Choose a provider" : activityError
                  }
                  {...params}
                  label="Activity"
                />
              )}
            />
          </FormControl>
        </Grid>

        {/*description*/}
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            disabled={formDisabled}
            rows={4}
            label="Description"
            placeholder=" Describe what students will be doing in this program"
            variant="outlined"
            inputProps={{ maxLength: 1000, autoComplete: "off" }}
            value={description}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
          />
        </Grid>

        {/*instructors*/}
        <Grid item xs={12}>
          <Error
            message="There was an issue loading eligible instructors."
            query={eligibleInstructorsQuery}
          />
          <FormControl fullWidth>
            <Autocomplete
              multiple
              disableCloseOnSelect
              disabled={
                providerId == null ||
                eligibleInstructorsQuery?.isLoading ||
                eligibleInstructorsQuery?.isError ||
                formDisabled
              }
              value={
                eligibleInstructors?.filter((i) =>
                  selectedInstructors?.includes(i.personId)
                ) ?? []
              }
              onChange={(event, value) => {
                setSelectedInstructors(value.map((v) => v.personId ?? v));
                setInstructorsError("");
              }}
              options={eligibleInstructors ?? []}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {`${option.firstName} ${option.lastName}`}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Instructors"
                  error={!!instructorsError}
                  helperText={
                    providerId == null ? "Choose a provider" : instructorsError
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    color="primary"
                    variant="contained"
                    label={GetInstructorName(option.personId)}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </FormControl>
        </Grid>

        {/*schools*/}
        <Grid item xs={12}>
          <Error
            message="There was an issue loading schools."
            query={schoolsQuery}
          />
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              disableCloseOnSelect
              disabled={
                providerId == null ||
                schoolsQuery?.isLoading ||
                schoolsQuery?.isError ||
                formDisabled
              }
              value={
                schools?.filter((s) =>
                  selectedSchools?.includes(s.locationId)
                ) ?? []
              }
              getOptionLabel={(option) => option.locationName}
              options={schools ?? []}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={props.id}>
                  <Checkbox checked={selected} />
                  {option.locationName}
                </li>
              )}
              multiple
              onChange={(event, value) => {
                setSelectedSchools(value.map((v) => v.locationId ?? v));
                setSchoolsError("");

                setSelectedGrades([]);
                setGradesError("");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Schools offered to"
                  error={!!schoolsError}
                  helperText={
                    providerId == null ? "Choose a provider" : schoolsError
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    color="primary"
                    variant="contained"
                    label={
                      schools?.find((s) => s.locationId === option.locationId)
                        ?.locationName
                    }
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </FormControl>
        </Grid>

        {/*grades*/}
        <Grid item xs={12}>
          <Error
            message="There was an issue loading grades."
            query={gradesQuery}
          />
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              disableCloseOnSelect
              disabled={
                selectedSchools == null ||
                selectedSchools?.length === 0 ||
                gradesQuery?.isLoading ||
                gradesQuery?.isError ||
                formDisabled
              }
              value={
                grades?.filter((g) => selectedGrades?.includes(g.gradeId)) ?? []
              }
              options={grades ?? []}
              getOptionLabel={(option) => option.gradeLevel}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {option.gradeLevel}
                </li>
              )}
              multiple
              onChange={(event, value) => {
                setSelectedGrades(value.map((v) => v.gradeId ?? v));
                setGradesError("");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Grades offered to"
                  error={!!gradesError}
                  helperText={
                    selectedSchools == null || selectedSchools?.length === 0
                      ? "Choose a school"
                      : gradesError
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    color="primary"
                    variant="contained"
                    label={getGradeName(
                      grades?.find((s) => s.gradeId === option.gradeId)
                        ?.gradeLevel
                    )}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </FormControl>
        </Grid>

        {/*session*/}
        <Grid item xs={6} sm={4}>
          <Error
            message="There was an issue loading sessions."
            query={sessionsQuery}
          />
          <FormControl fullWidth>
            <InputLabel error={!!sessionError} id="session-label" required>
              Session
            </InputLabel>
            <Select
              required
              labelId="session-label"
              disabled={
                formDisabled ||
                sessionsQuery?.isLoading ||
                sessionsQuery?.isError
              }
              options={sessions}
              onChange={(event) => {
                setSessionId(event.target.value);
                setSessionError("");
              }}
              value={sessionId ?? ""}
              label="Session"
              error={!!sessionError}
            >
              {sessions?.map((session) => (
                <MenuItem key={session.sessionId} value={session.sessionId}>
                  {session.sessionName}
                </MenuItem>
              ))}
            </Select>
            {sessionError && (
              <FormHelperText error={true}>{sessionError}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/*maxEnrollment*/}
        <Grid item xs={6} sm={3}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled}
              required
              fullWidth
              label="Max Enrollment"
              variant="outlined"
              inputProps={{
                type: "number",
                min: 0,
                step: 1,
              }}
              value={maxEnrollment}
              onChange={(event) => {
                setMaxEnrollment(event.target.value);
                setMaxEnrollmentError(false);
              }}
              error={!!maxEnrollmentError}
              helperText={maxEnrollmentError ? "Required" : ""}
            />
          </FormControl>
        </Grid>

        {/*restrictions*/}
        <Grid item xs={10} sm={4}>
          <Error
            message="There was an issue loading restrictions."
            query={restrictionsQuery}
          />
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              disableCloseOnSelect
              disabled={
                restrictionsQuery?.isLoading ||
                restrictionsQuery?.isError ||
                formDisabled
              }
              value={
                restrictions?.filter((r) =>
                  selectedRestrictions?.includes(r.restrictionId)
                ) ?? []
              }
              options={restrictions ?? []}
              getOptionLabel={(option) => option.name ?? ""}
              multiple
              onChange={(event, value) => {
                if (value.filter((v) => v.domain === "gender")?.length > 1) {
                  snackbar.enqueueSnackbar(
                    `More than one gender cannot be selected.`,
                    {
                      variant: "error",
                    }
                  );
                } else {
                  setSelectedRestrictions(
                    value.map((v) => v.restrictionId ?? v)
                  );
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Special Eligibility"
                  // error={gradesError}
                  // helperText={
                  //   selectedSchools == null || selectedSchools?.length == 0
                  //     ? "Choose a school"
                  //     : gradesError
                  // }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    color="primary"
                    variant="contained"
                    label={
                      restrictions?.find(
                        (s) => s.restrictionId === option.restrictionId
                      )?.name
                    }
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} sm={1}>
          <Tooltip title="Use these to filter which groups of students may be enrolled into the program. This should rarely be used.">
            <IconButton>
              <FontAwesomeIcon icon={faInfoCircle} />
            </IconButton>
          </Tooltip>
        </Grid>

        {/*cycle*/}
        <Grid item xs={12}>
          <Error
            message="There was an issue loading cycles."
            query={cyclesQuery}
          />
          <Autocomplete
            disabled={
              formDisabled || cyclesQuery?.isLoading || cyclesQuery?.isError
            }
            loading={cyclesQuery?.isLoading || cyclesQuery?.isError}
            disablePortal
            options={!!cycles ? cycles : []}
            getOptionLabel={(option) => option?.cycleName}
            value={cycles?.find((c) => c.cycleId === cycleId) ?? null}
            onChange={(event, value) => {
              setCycleId(value?.cycleId);
              setCycleError("");
            }}
            renderInput={(params) => (
              <TextField
                error={!!cycleError}
                helperText={cycleError}
                required
                {...params}
                label="Cycle"
              />
            )}
          />
        </Grid>

        {/*regStartDate*/}
        <Grid item xs={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={formDisabled}
              label="Registration Start"
              format="MM/DD/YY"
              value={regStartDate ? dayjs(regStartDate) : null}
              onChange={(value) => {
                setRegStartDate(value);
                setRegStartDateError("");
                if (regEndDate == null) setRegEndDate(value);
              }}
              slotProps={{
                textField: {
                  required: true,
                  fullWidth: true,
                  label: "Registration Start",
                  error: regStartDateError,
                  helperText: (
                    <span>
                      Time automatically set to 7:00am{" "}
                      {!!regStartDateError && (
                        <em>
                          <br />
                          {regStartDateError}
                        </em>
                      )}
                    </span>
                  ),
                },
              }}
            />
          </LocalizationProvider>
        </Grid>

        {/*regEndDate*/}
        <Grid item xs={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={formDisabled}
              label="Registration End"
              format="MM/DD/YY"
              value={regEndDate ? dayjs(regEndDate) : null}
              onChange={(value) => {
                setRegEndDate(value);
                setRegEndDateError("");
              }}
              slotProps={{
                textField: {
                  required: true,
                  fullWidth: true,
                  label: "Registration End",
                  error: regEndDateError,
                  helperText: (
                    <span>
                      Time automatically set to 11:59pm{" "}
                      {!!regEndDateError && (
                        <em>
                          <br />
                          {regEndDateError}
                        </em>
                      )}
                    </span>
                  ),
                },
              }}
            />
          </LocalizationProvider>
        </Grid>

        {/*startDate*/}
        <Grid item xs={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={formDisabled}
              label="Start"
              format="MM/DD/YY"
              value={startDate ? dayjs(startDate) : null}
              onChange={(value) => {
                setStartDate(value);
                setStartDateError("");
                if (endDate == null) setEndDate(value);
              }}
              slotProps={{
                textField: {
                  required: true,
                  fullWidth: true,
                  label: "Start",
                  error: !!startDateError,
                  helperText: startDateError,
                },
              }}
            />
          </LocalizationProvider>
        </Grid>

        {/*endDate*/}
        <Grid item xs={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={formDisabled}
              label="End"
              format="MM/DD/YY"
              value={endDate ? dayjs(endDate) : null}
              onChange={(value) => {
                setEndDate(value);
                setEndDateError("");
              }}
              slotProps={{
                textField: {
                  required: true,
                  fullWidth: true,
                  label: "End",
                  error: !!endDateError,
                  helperText: endDateError,
                },
              }}
            />
          </LocalizationProvider>
        </Grid>

        {/*advertiseDate*/}
        <Grid item xs={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={formDisabled}
              label="Advertise Date"
              format="MM/DD/YY"
              value={advertiseDate ? dayjs(advertiseDate) : null}
              onChange={(value) => {
                setAdvertiseDate(value);
              }}
              slotProps={{
                textField: {
                  required: true,
                  fullWidth: true,
                  label: "Advertise Date",
                },
              }}
            />
          </LocalizationProvider>
        </Grid>

        {/*meetingUrl*/}
        <Grid item xs={12}>
          <TextField
            disabled={formDisabled}
            fullWidth
            label="Meeting URL"
            variant="outlined"
            inputProps={{ maxLength: 255 }}
            value={meetingUrl}
            onChange={(event) => {
              setMeetingUrl(event.target.value);
            }}
          />
        </Grid>

        {/*Location*/}
        <Grid item xs={12}>
          <Error
            message="There was an issue loading locations."
            query={locationsQuery}
          />
          <Autocomplete
            disabled={
              formDisabled ||
              locationsQuery?.isLoading ||
              locationsQuery?.isError
            }
            loading={locationsQuery?.isLoading || locationsQuery?.isError}
            disablePortal
            options={!!locations ? locations : []}
            getOptionLabel={(option) => option?.locationName}
            value={locations?.find((l) => l.locationId === locationId) ?? null}
            onChange={(event, value) => {
              setLocationId(value?.locationId);
              setLocationError("");
            }}
            renderInput={(params) => (
              <TextField
                error={!!locationError}
                helperText={locationError}
                required
                {...params}
                key={params.id}
                label="Location"
              />
            )}
          />
        </Grid>

        {/*room*/}
        <Grid item xs={6} sm={5}>
          <TextField
            disabled={formDisabled}
            fullWidth
            label="Room"
            variant="outlined"
            inputProps={{ maxLength: 32 }}
            value={room || ""}
            onChange={(event) => {
              setRoom(event.target.value);
              setRoomError(null);
            }}
            error={!!roomError}
            helperText={roomError}
          />
        </Grid>

        {/*defaultStartTime*/}
        <Grid item xs={6} sm={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
              disabled={formDisabled}
              label="Start Time"
              value={defaultStartTime ? dayjs(defaultStartTime) : null}
              onChange={(value) => {
                setDefaultStartTime(value);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        {/*duration*/}
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled}
              required
              fullWidth
              label="Duration (hours)"
              variant="outlined"
              inputProps={{
                type: "number",
                min: 0,
                step: 0.25,
              }}
              value={defaultDurationHours ?? 0}
              onChange={(event) => {
                setDefaultDurationHours(event.target.value);
                setDefaultDurationError("");
              }}
              error={!!defaultDurationError}
              helperText={defaultDurationError}
            />
          </FormControl>
        </Grid>

        {/*cost*/}
        <Grid item xs={11} sm={2}>
          <FormControl fullWidth>
            <InputLabel htmlFor="program-cost">Cost</InputLabel>
            <OutlinedInput
              id="program-cost"
              value={cost ?? currency("0")}
              onChange={(event) => setCost(event.target.value)}
              onBlur={() =>
                setCost(
                  isNaN(currency(cost)) ? currency("0") : currency(cost).value
                )
              }
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              label="Cost"
              error={!!costError}
              // helperText={costError}
            />
          </FormControl>
        </Grid>

        <Grid item xs={1}>
          <Tooltip title="Set a maximum cost a parent can pay for this program. Parents will have the option to pay full price, half price or free.">
            <IconButton>
              <FontAwesomeIcon icon={faInfoCircle} />
            </IconButton>
          </Tooltip>
        </Grid>

        {/* Program Days */}
        {(regularDays?.length > 0 || (startDate && endDate && cycleId)) && (
          <>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Typography
                    variant="h4"
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    Scheduled Days
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip title="Note: Scheduled days will be recalculated when the cycle, start date, or end date changes when creating a new program. Event days will not show up on the day-picker below, but will show up on the table. Days before the start date or after the end date will be disabled from selection.">
                    <IconButton>
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container spacing={2} justifyContent="space-around">
                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <DayPicker
                    onDayClick={(date) => {
                      AddRemoveRegularDay(date);
                    }}
                    defaultMonth={
                      dayjs(startDate ?? new Date()).toDate() ?? new Date()
                    }
                    modifiersStyles={calendarStyles}
                    selected={regularDays?.map((pd) => {
                      return new Date(pd.scheduledDate);
                    })}
                    disabled={[
                      {
                        before: new Date(startDate),
                      },
                      {
                        after: new Date(endDate),
                      },
                      disabledDates?.map((dd) => new Date(dd)),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <TableContainer sx={programDaysListStyles}>
                    <Table size="small">
                      <TableBody>
                        {programDays?.map((pd, i) => (
                          <TableRow key={i}>
                            <TableCell>{pd.isEvent && "Custom day"}</TableCell>
                            <TableCell>
                              {formatDate(pd.scheduledDate)}
                            </TableCell>
                            <TableCell>{pd.room}</TableCell>
                            <TableCell>
                              {pd.startTime
                                ? formatTime(pd.startTime)
                                : "(No time set)"}
                            </TableCell>
                            <TableCell>
                              {pd.durationHours ?? "--"} hours
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                {/* <Grid item xs={12}>
                <Typography variant="h5" color={theme.palette.text.secondary}>
                  Custom Days
                </Typography>
                {eventDays?.length > 0 ? (
                  eventDays.map((ed, i) => {
                    return (
                      <Grid
                        container
                        key={i}
                        spacing={2}
                        sx={{ m: theme.spacing(1) }}
                        alignItems="center"
                        // className={classes.eventItem}
                      >
                        <Grid item xs={12} sm={6} md={4}>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DatePicker
                              disabled={formDisabled}
                              label="Date"
                              inputFormat="MM/DD/YY"
                              value={ed.scheduledDate ?? dayjs()}
                              onChange={(date) => {
                                updateEventDay(i, "scheduledDate", date);
                              }}
                              invalidDateMessage="MM/DD/YYYY - Please use this date format."
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  fullWidth
                                  label="Date"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <TimePicker
                              error={false}
                              disabled={formDisabled}
                              label="Start Time"
                              value={ed.startTime}
                              onChange={(time) => {
                                updateEventDay(i, "startTime", time);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} error={false} />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                          <TextField
                            fullWidth
                            required
                            variant="outlined"
                            label="Duration (in hours)"
                            inputProps={{
                              type: "number",
                              min: 0,
                              step: 0.25,
                            }}
                            value={ed.durationHours}
                            onChange={(e) => {
                              updateEventDay(
                                i,
                                "durationHours",
                                e.target.value
                              );
                            }}
                            // error={durationError}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            required
                            fullWidth
                            variant="outlined"
                            label="Room / Meeting Location"
                            value={ed.room}
                            inputProps={{ maxLength: 32 }}
                            onChange={(e) => {
                              updateEventDay(i, "room", e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2} md={1}>
                          <IconButton
                            onClick={() => {
                              const hasAttendance = disabledDates?.find(
                                (a) =>
                                  a.programDayId ===
                                  eventDays[i]?.programDayId
                              );
                              if (hasAttendance) {
                                snackbar.enqueueSnackbar(
                                  `This day has attendance and cannot be removed.`,
                                  {
                                    variant: "error",
                                  }
                                );
                                return;
                              } else {
                                setEventToRemove(i);
                              }
                            }}
                            aria-label="Remove Event Day"
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })
                ) : (
                  <Typography>No custom days yet</Typography>
                )}
                {
                  <Typography color={theme.palette.error.main}>
                    {eventDaysError}
                  </Typography>
                }

                <Dialog
                  open={eventToRemove != null}
                  onBackdropClick={() => {
                    setEventToRemove(null);
                  }}
                  fullWidth
                  maxWidth={"sm"}
                >
                  <DialogTitle>Confirm Custom Day Removal</DialogTitle>
                  <DialogContent>
                    {eventToRemove != null && (
                      <Grid container>
                        <Grid item xs={3}>
                          <Typography variant="h6" component="strong">
                            {formatDate(
                              eventDays[eventToRemove]?.scheduledDate
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="h6" component="strong">
                            {eventDays[eventToRemove]?.startTime?.format(
                              "h:mm a"
                            )}
                          </Typography>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography variant="h6" component="strong">
                            {eventDays[eventToRemove]?.durationHours} hours
                          </Typography>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography variant="h6" component="strong">
                            {eventDays[eventToRemove]?.room}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          Changes won't take effect until you Submit your
                          changes.
                        </Grid>
                      </Grid>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Grid container spacing={2} justify="flex-end">
                      <Grid item>
                        <Button
                          onClick={() => {
                            setEventToRemove(null);
                          }}
                          color="primary"
                          variant="outlined"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => {
                            removeEventDay(eventToRemove);
                          }}
                          color="primary"
                          variant="contained"
                        >
                          Confirm
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </Dialog>

                <Button
                  onClick={() => {
                    addNewEventDay();
                  }}
                  variant={"contained"}
                  color={"primary"}
                  startIcon={<FontAwesomeIcon icon={faPlus} />}
                  sx={{ mt: theme.spacing(1) }}
                >
                  Add Custom Day
                </Button>
              </Grid> */}
              </Grid>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {programDaysError && (
            <FormControl>
              <FormHelperText error={!!programDaysError}>
                {programDaysError}
              </FormHelperText>
            </FormControl>
          )}
        </Grid>

        {/*teeShirtRequired*/}
        <Grid item xs={12}>
          <FormControlLabel
            disabled={formDisabled}
            checked={teeShirtRequired}
            onChange={(event) => {
              setTeeShirtRequired(event.target.checked);
            }}
            control={<Checkbox />}
            label="T-Shirt Required"
          />
          <Tooltip title="Parents will be able to update their student's t-shirt size if this is checked">
            <IconButton>
              <FontAwesomeIcon icon={faInfoCircle} />
            </IconButton>
          </Tooltip>
        </Grid>

        {/*fixedPricing*/}
        <Grid item xs={12}>
          <FormControlLabel
            disabled={formDisabled}
            checked={fixedCost}
            onChange={(event) => {
              setFixedCost(event.target.checked);
            }}
            control={<Checkbox />}
            label="Fixed Cost"
          />
          <Tooltip title="Cost of the program will be fixed. Guardians will have no options except to agree to the full cost.">
            <IconButton>
              <FontAwesomeIcon icon={faInfoCircle} />
            </IconButton>
          </Tooltip>
        </Grid>

        {/*manualEnrollment*/}
        <Grid item xs={12}>
          <FormControlLabel
            disabled={formDisabled}
            checked={manualEnrollment}
            onChange={(event) => {
              setManualEnrollment(event.target.checked);
            }}
            control={<Checkbox />}
            label="Manual Enrollment"
          />
          <Tooltip title="Prevent enrollment through Compass, but it will still be advertised there unless 'Hide in Compass' is checked.">
            <IconButton>
              <FontAwesomeIcon icon={faInfoCircle} />
            </IconButton>
          </Tooltip>
        </Grid>

        {/*hideInCompass*/}
        <Grid item xs={12}>
          <FormControlLabel
            disabled={formDisabled}
            checked={hideInCompass}
            onChange={(event) => {
              setHideInCompass(event.target.checked);
            }}
            control={<Checkbox />}
            label="Hide in Compass"
          />
        </Grid>

        {/*enableTransportation*/}
        <Grid item xs={12}>
          <FormControlLabel
            disabled={formDisabled}
            checked={enableTransportation}
            onChange={(event) => {
              setEnableTransportation(event.target.checked);
            }}
            control={<Checkbox />}
            label="Enable Transportation Options"
          />
        </Grid>

        {/*hideCost*/}
        {/* <Grid item xs={12}>
        <FormControlLabel
          disabled={formDisabled}
          checked={hideCost}
          onChange={(event) => {
            setHideCost(event.target.checked);
          }}
          control={<Checkbox />}
          label="Hide Cost"
        />
        <Tooltip
          title={`Determine if the option to "Pay What you Choose" is available or hidden to families. Available by default.`}
        >
          <IconButton>
            <FontAwesomeIcon icon={faInfoCircle} />
          </IconButton>
        </Tooltip>
      </Grid> */}

        {/*isDraft*/}
        <Grid item xs={12}>
          <FormControlLabel
            disabled={
              !user?.permissions?.includes(ManagePrograms) ||
              details?.participants?.length > 0 ||
              !userCanEdit ||
              formDisabled
            }
            checked={
              user?.permissions?.includes(DraftPrograms) &&
              !user?.permissions?.includes(ManagePrograms)
                ? true
                : isDraft
            }
            onChange={(event) => {
              setIsDraft(event.target.checked);
            }}
            control={<Checkbox />}
            label="Draft"
          />
        </Grid>

        {userCanEdit && (
          <Grid item>
            <Button
              disabled={formDisabled}
              onClick={handleClickSave}
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faSave} />}
              endIcon={
                upsertProgram?.isLoading && <CircularProgress size={20} />
              }
            >
              Save
            </Button>
          </Grid>
        )}

        <Grid item>
          <Button
            disabled={upsertProgram?.isLoading}
            onClick={() => {
              navigate("/programs");
            }}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Error
            message="There was a problem saving the program."
            query={upsertProgram}
          />
        </Grid>
      </Grid>
    </>
  );
}
