import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faFilterSlash } from "@fortawesome/pro-solid-svg-icons";
import {
  ScrollToTopOnMount,
  getSchoolYear,
  getGradeName,
} from "../../services/utilities";
import { useGetSlicers } from "../../services/hooks/partnerReportsHook";

export default function Reports() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const ChangeTab = (event, newValue) => {
    navigate(`/reports/program/${newValue}`);
  };

  const GetSelectedTab = () => {
    const tab = location.pathname
      .replace("/reports/program/", "")
      .replace("/reports/program", "");
    if (tab === "") {
      return "demographics"; //setting a default avoids console error if URL is /reports/instructor and then redirects to full path
    }
    return tab;
  };

  const [selectedProviderIds, setSelectedProviderIds] = useState([]);
  const [selectedSchoolYears, setSelectedSchoolYears] = useState([
    getSchoolYear(),
  ]);
  const [selectedSessionIds, setSelectedSessionIds] = useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);
  const [selectedActivityTypeIds, setSelectedActivityTypeIds] = useState([]);
  const [selectedActivityIds, setSelectedActivityIds] = useState([]);
  //participant filters
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedRaces, setSelectedRaces] = useState([]);
  const [showFilters, setShowFilters] = useState(true);

  const slicers = {
    providerIds: selectedProviderIds,
    schoolYears: selectedSchoolYears,
    sessionIds: selectedSessionIds,
    locationIds: selectedLocationIds,
    activityIds: selectedActivityIds,
    activityTypeIds: selectedActivityTypeIds,
    grades: selectedGrades,
    genders: selectedGenders,
    races: selectedRaces,
  };

  //SLICERS

  //All Slicers
  const slicerQuery = useGetSlicers();
  const allSlicers = slicerQuery?.data?.data;

  //Providers
  const providers = useMemo(() => {
    return allSlicers?.providers ?? [];
  }, [allSlicers]);

  //School Years
  const schoolYears = useMemo(() => {
    return allSlicers?.schoolYears?.map((s) => s.schoolYear) ?? [];
  }, [allSlicers]);

  //Sessions
  const sessions = useMemo(() => {
    return allSlicers?.sessions ?? [];
  }, [allSlicers]);

  //Locations
  const locations = useMemo(() => {
    return allSlicers?.locations ?? [];
  }, [allSlicers]);

  //Activity Types
  const activityTypes = useMemo(() => {
    return allSlicers?.activityTypes ?? [];
  }, [allSlicers]);

  //Activities
  const activities = useMemo(() => {
    return allSlicers?.activities ?? [];
  }, [allSlicers]);

  //Grades
  const grades = useMemo(() => {
    return allSlicers?.grades ?? [];
  }, [allSlicers]);

  //Genders
  const genders = useMemo(() => {
    return allSlicers?.genders ?? [];
  }, [allSlicers]);

  //Races
  const races = useMemo(() => {
    return allSlicers?.races ?? [];
  }, [allSlicers]);

  const clearFilters = () => {
    setSelectedProviderIds([]);
    setSelectedSchoolYears([getSchoolYear()]);
    setSelectedSessionIds([]);
    setSelectedLocationIds([]);
    setSelectedActivityTypeIds([]);
    setSelectedActivityIds([]);
    setSelectedGrades([]);
    setSelectedGenders([]);
    setSelectedRaces([]);
  };

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="h1">Program Reports</Typography>{" "}
        </Grid>
        <Grid item>
          <Button
            size="small"
            onClick={handleToggleFilters}
            sx={{ width: "130px" }}
            // endIcon seemed too big, so placed it inline
          >
            {showFilters ? (
              <span>
                Hide&nbsp;Filters&nbsp;
                <FontAwesomeIcon icon={faFilterSlash} />
              </span>
            ) : (
              <span>
                Show&nbsp;Filters&nbsp;
                <FontAwesomeIcon icon={faFilter} />
              </span>
            )}
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          xl={3}
          sx={{
            display: `${showFilters ? "auto" : "none"}`,
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ mt: theme.spacing(2) }}
          >
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography variant="h6">
                    <FontAwesomeIcon icon={faFilter} />
                    &nbsp;Filters
                  </Typography>
                </Grid>
                <Grid item>
                  <Button onClick={() => clearFilters()}>Clear</Button>
                </Grid>
              </Grid>
            </Grid>

            {/* School Year */}
            <Grid item xs={12} sm={6} lg={4} xl={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  disablePortal
                  disabled={slicerQuery.isLoading}
                  value={selectedSchoolYears ?? []}
                  options={schoolYears ?? []}
                  getOptionLabel={(option) => option ?? ""}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option}
                    </li>
                  )}
                  onChange={(event, value) => {
                    setSelectedSchoolYears(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="School Years"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {slicerQuery.isLoading && (
                              <CircularProgress size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        color="primary"
                        variant="contained"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </FormControl>
            </Grid>

            {/* Provider */}
            <Grid item xs={12} sm={6} lg={4} xl={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  disablePortal
                  disabled={slicerQuery.isLoading}
                  value={
                    providers?.filter((p) =>
                      selectedProviderIds?.includes(p.providerID ?? 0)
                    ) ?? []
                  }
                  options={providers ?? []}
                  getOptionLabel={(option) => option.name ?? ""}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option.name}
                    </li>
                  )}
                  onChange={(event, value) => {
                    setSelectedProviderIds(value.map((v) => v.providerID));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Providers"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {slicerQuery.isLoading && (
                              <CircularProgress size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        color="primary"
                        variant="contained"
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </FormControl>
            </Grid>

            {/* Sessions */}
            <Grid item xs={12} sm={6} lg={4} xl={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  disablePortal
                  disabled={slicerQuery.isLoading}
                  value={
                    sessions?.filter((s) =>
                      selectedSessionIds?.includes(s.sessionID ?? 0)
                    ) ?? []
                  }
                  options={sessions ?? []}
                  getOptionLabel={(option) => option.name ?? ""}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option.name}
                    </li>
                  )}
                  onChange={(event, value) => {
                    setSelectedSessionIds(value.map((v) => v.sessionID));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sessions"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {slicerQuery.isLoading && (
                              <CircularProgress size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        color="primary"
                        variant="contained"
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </FormControl>
            </Grid>

            {/* Locations */}
            <Grid item xs={12} sm={6} lg={4} xl={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  disablePortal
                  disabled={slicerQuery.isLoading}
                  value={
                    locations?.filter((l) =>
                      selectedLocationIds?.includes(l.locationID ?? 0)
                    ) ?? []
                  }
                  options={locations ?? []}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option.name}
                    </li>
                  )}
                  onChange={(event, value) => {
                    setSelectedLocationIds(value.map((v) => v.locationID));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Locations"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {slicerQuery.isLoading && (
                              <CircularProgress size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        color="primary"
                        variant="contained"
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </FormControl>
            </Grid>

            {/* Activity Types */}
            <Grid item xs={12} sm={6} lg={4} xl={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  disablePortal
                  disabled={slicerQuery.isLoading}
                  value={
                    activityTypes?.filter((a) =>
                      selectedActivityTypeIds?.includes(a.activityTypeID ?? 0)
                    ) ?? []
                  }
                  options={activityTypes ?? []}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option.name}
                    </li>
                  )}
                  onChange={(event, value) => {
                    setSelectedActivityTypeIds(
                      value.map((v) => v.activityTypeID)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Activity Types"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {slicerQuery.isLoading && (
                              <CircularProgress size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        color="primary"
                        variant="contained"
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </FormControl>
            </Grid>

            {/* Activities */}
            <Grid item xs={12} sm={6} lg={4} xl={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  disablePortal
                  disabled={slicerQuery.isLoading}
                  value={
                    activities?.filter((a) =>
                      selectedActivityIds?.includes(a.activityID ?? 0)
                    ) ?? []
                  }
                  options={activities ?? []}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option.name}
                    </li>
                  )}
                  onChange={(event, value) => {
                    setSelectedActivityIds(value.map((v) => v.activityID));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Activities"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {slicerQuery.isLoading && (
                              <CircularProgress size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        color="primary"
                        variant="contained"
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </FormControl>
            </Grid>

            {/* Grades */}
            <Grid item xs={12} sm={6} lg={4} xl={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  disablePortal
                  disabled={slicerQuery.isLoading}
                  value={
                    grades?.filter((g) =>
                      selectedGrades?.includes(g.grade ?? 0)
                    ) ?? []
                  }
                  options={grades ?? []}
                  getOptionLabel={(option) =>
                    option ? getGradeName(option.grade) : "Unknown"
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option ? getGradeName(option.grade) : "Unknown"}
                    </li>
                  )}
                  onChange={(event, value) => {
                    setSelectedGrades(value.map((v) => v.grade));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Grades"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {slicerQuery.isLoading && (
                              <CircularProgress size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        color="primary"
                        variant="contained"
                        label={option ? getGradeName(option.grade) : "Unknown"}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </FormControl>
            </Grid>

            {/* Genders */}
            <Grid item xs={12} sm={6} lg={4} xl={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  disablePortal
                  disabled={slicerQuery.isLoading}
                  value={
                    genders?.filter((sp) => selectedGenders?.includes(sp)) ?? []
                  }
                  options={genders ?? []}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option}
                    </li>
                  )}
                  onChange={(event, value) => {
                    setSelectedGenders(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Genders"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {slicerQuery.isLoading && (
                              <CircularProgress size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        color="primary"
                        variant="contained"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </FormControl>
            </Grid>

            {/* Races */}
            <Grid item xs={12} sm={6} lg={4} xl={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  disablePortal
                  disabled={slicerQuery.isLoading}
                  value={races?.filter((r) => selectedRaces?.includes(r)) ?? []}
                  options={races ?? []}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option}
                    </li>
                  )}
                  onChange={(event, value) => {
                    setSelectedRaces(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Races"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {slicerQuery.isLoading && (
                              <CircularProgress size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        color="primary"
                        variant="contained"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} xl={showFilters ? 9 : 12}>
          <Box sx={{ maxWidth: "98vw" }}>
            <Tabs
              onChange={ChangeTab}
              value={GetSelectedTab()}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab value="programs" label="Programs" />
              <Tab value="demographics" label="Demographics" />
              <Tab value="attendance" label="Attendance" />
            </Tabs>
            <Divider />
            <br />
            <Outlet context={[slicers]} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
