import { useMemo, useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Outlet, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import {
  faBars,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faSignOutAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { useTheme, darken, alpha } from "@mui/material/styles";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PartnerLogoFull from "../images/PartnerLogoFull";
import PartnerLogoSmall from "../images/PartnerLogoSmall";
import TpsLogo from "../images/TpsLogo";
import useNavItems from "./useNavItems";
import { useUser } from "../../services/contexts/userContext";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useUpsertUserSettings } from "../../services/hooks/personHook";
dayjs.extend(isSameOrAfter);

export default function Nav(props) {
  const theme = useTheme();
  const { user, userQuery } = props;
  const commonStyles = useCommonStyles();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [privacyAgreementOpen, setPrivacyAgreementOpen] = useState(false);
  const [privacyStatementOutOfDate, setPrivacyStatementOutOfDate] =
    useState(true);
  const { userLoading } = useUser();
  const navItems = useNavItems();

  const [logoutConfirmOpen, setLogoutConfirmOpen] = useState(false);
  const [openSubMenus] = useState(new Set());

  useEffect(() => {
    if (user && user?.settings) {
      const settings = JSON.parse(user.settings);
      const privacyAgreementDateTime = dayjs(
        settings?.PrivacyAgreementDateTime
      );
      const dateToCheckAgainst = dayjs().add(-7, "day");
      if (privacyAgreementDateTime.isSameOrAfter(dateToCheckAgainst)) {
        setPrivacyStatementOutOfDate(false);
      } else {
        setPrivacyStatementOutOfDate(true);
        setPrivacyAgreementOpen(true);
      }
    } else {
      setPrivacyAgreementOpen(true);
    }
  }, [user]);

  const closeLogoutConfirm = () => setLogoutConfirmOpen(false);

  const upsertUserSettings = useUpsertUserSettings();

  const submitPrivacyAgreement = () => {
    upsertUserSettings.mutateAsync().then((response) => {
      setPrivacyAgreementOpen(false);
    });
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const isSubMenuOpen = (navItem) => {
    if (openSubMenus.has(navItem)) {
      openSubMenus.delete(navItem);
      return false;
    } else {
      openSubMenus.add(navItem);
      return true;
    }
  };

  const smallScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const { instance } = useMsal();

  const isSelected = (path) => {
    if (path === "/") {
      return Boolean(location?.pathname === "/");
    } else
      return Boolean(location?.pathname?.replace("/", "")?.startsWith(path));
  };

  const logout = () => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  //Styles
  const appBarStyles = {
    color: theme.palette.nav?.contrastText,
    background: theme.palette.nav?.main,
    borderRadius: 0,
  };
  const centerContentStyles = {
    display: "flex",
    justifyContent: "center",
    my: theme.spacing(2),
  };
  const partnerLogoStyles = {
    ...centerContentStyles,
    height: 100,
  };
  const drawerStyles = {
    "& .MuiDrawer-paper": {
      background: `${theme.palette.nav?.main}`,
      boxSizing: "border-box",
      color: theme.palette.primary.contrastText,
      overflowX: "hidden",
      width: commonStyles.drawerWidth,
    },
    width: commonStyles.drawerWidth,
  };
  const footerStyles = {
    marginTop: "auto",
  };
  const navIconStyles = {
    color: theme.palette.nav?.contrastTextSecondary,
    justifyContent: "center",
  };
  const openNavStyles = {
    ...navIconStyles,
    width: "3rem",
    height: "3rem",
    marginRight: "0.5rem",
  };
  const closeNavStyles = {
    ...navIconStyles,
    width: "3rem",
    height: "3rem",
    margin: "0.5rem",
    "& svg": {
      marginLeft: "-4px",
    },
  };
  const navItemStyles = {
    color: theme.palette.nav?.contrastText,
    // transition: ".4s ease all",
    m: `${theme.spacing(1)} auto`,
    px: theme.spacing(1),
    py: theme.spacing(0.5),
    borderRadius: 999,
    width: "85%",
    "& .MuiListItemIcon-root": {
      color: theme.palette.nav?.contrastText,
    },
    "&:hover": {
      background: theme.palette.nav?.light,
    },
    "&.Mui-selected": {
      transform: "scale(1.05)",
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
      boxShadow: theme.shadows[2],
      "&:hover": {
        background: darken(theme.palette.primary.main, 0.2),
      },
      "& .MuiListItemIcon-root": {
        color: theme.palette.primary?.contrastText,
      },
    },
  };
  const subNavStyles = {
    pl: 6,
    mt: "-0.5rem",
  };
  const subNavItemStyles = {
    color: theme.palette.nav?.contrastText,
    m: `${theme.spacing(1)} auto`,
    p: 0,
    borderRadius: 999,
    width: "85%",
    "& .MuiListItemIcon-root": {
      color: theme.palette.nav?.contrastText,
    },
    "&:hover": {
      background: theme.palette.nav?.light,
    },
    "& .MuiListItemText-primary": {
      fontSize: "0.8rem",
    },
    "&.Mui-selected": {
      transform: "scale(1.05)",
      color: theme.palette.primary.contrastText,
      background: alpha(theme.palette.primary.main, 0.65),
      boxShadow: theme.shadows[2],
      "&:hover": {
        background: darken(theme.palette.primary.main, 0.2),
      },
      "& .MuiListItemIcon-root": {
        color: theme.palette.primary?.contrastText,
      },
    },
  };
  const pageWrapStyles = () => {
    // adding styles based on page or section
    const path = location?.pathname?.split("/");
    if (!!theme?.palette?.pages && !!path[1]) {
      return {
        minHeight: "100vh",
        ...theme.palette.pages[path[1]],
      };
    } else
      return {
        minHeight: "100vh",
      };
  };

  //nav items
  const navItemsMemoized = useMemo(() => {
    /* 
      The array of nav elements must all be built at the  
      same time or the animation does not work properly. 
      This is ugly, but this is the only way I could get 
      it to work. I will answer to God when the time comes.
      -AT
    */
    var navItemElements = [];

    navItems
      ?.filter((item) => item.enabled === true)
      ?.forEach((navItem, index) => {
        navItemElements.push(
          <div key={index}>
            <NavLink
              to={!navItem?.subMenu && navItem.to}
              onClick={() => {
                !!navItem?.subMenu
                  ? isSubMenuOpen(navItem.name)
                  : setDrawerOpen(false);
              }}
              style={{ textDecoration: "none" }}
            >
              <ListItem
                divider={
                  theme?.palette.nav?.main !== "transparent" &&
                  index === navItem.length - 1
                }
                button
                sx={navItemStyles}
                selected={isSelected(navItem.to)}
              >
                <ListItemIcon sx={navIconStyles}>
                  {navItem.icon && <FontAwesomeIcon icon={navItem.icon} />}
                </ListItemIcon>
                <ListItemText primary={navItem.name} />
                {!!navItem?.subMenu && (
                  <FontAwesomeIcon
                    icon={
                      isSubMenuOpen(navItem.name) ? faChevronDown : faChevronUp
                    }
                    style={{ marginRight: "0.5rem" }}
                  />
                )}
              </ListItem>
            </NavLink>
            {!!navItem?.subMenu && (
              <Collapse in={isSubMenuOpen(navItem.name)} sx={subNavStyles}>
                {navItem.subMenu.map((subNavItem, index2) => {
                  return (
                    <Box key={`${index}-${index2}`}>
                      <NavLink
                        to={subNavItem.to}
                        onClick={() => {
                          setDrawerOpen(false);
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <ListItem
                          button
                          sx={subNavItemStyles}
                          selected={isSelected(subNavItem.to)}
                        >
                          <ListItemIcon sx={navIconStyles}>
                            {subNavItem.icon && (
                              <FontAwesomeIcon icon={subNavItem.icon} />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={subNavItem.name} />
                        </ListItem>
                      </NavLink>
                    </Box>
                  );
                })}
              </Collapse>
            )}
            {Boolean(isSelected(navItem.to)) && (
              <Helmet>
                <title>
                  {navItem.name} | {process.env.REACT_APP_NAME}
                </title>
              </Helmet>
            )}
          </div>
        );
      });

    navItemElements.push(
      <div key={-1}>
        <NavLink
          to={""}
          onClick={() => {
            setDrawerOpen(false);
          }}
          style={{ textDecoration: "none" }}
        >
          <ListItem
            button
            sx={navItemStyles}
            onClick={() => setLogoutConfirmOpen(true)}
          >
            <ListItemIcon sx={navIconStyles}>
              <FontAwesomeIcon icon={faSignOutAlt} />
            </ListItemIcon>
            <ListItemText primary={"Log out"} />
          </ListItem>
        </NavLink>
      </div>
    );

    return navItemElements;
  }, [navItems]);

  return (
    <>
      {process.env.REACT_APP_ENVIRONMENT !== "production" && (
        <Chip
          size="small"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            position: "fixed",
            top: smallScreen ? 20 : 85,
            left: smallScreen ? 320 : 200,
            zIndex: 99999,
          }}
          label={`${process.env.REACT_APP_ENVIRONMENT}`}
        />
      )}
      <Box sx={pageWrapStyles}>
        <Box sx={{ display: "flex" }}>
          {smallScreen && (
            <AppBar position="fixed" open={drawerOpen} sx={appBarStyles}>
              <Toolbar>
                <IconButton
                  sx={openNavStyles}
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                >
                  <FontAwesomeIcon icon={faBars} />
                </IconButton>
                <NavLink to="/">
                  <PartnerLogoSmall theme={theme} />
                </NavLink>
              </Toolbar>
            </AppBar>
          )}
          <Drawer
            sx={drawerStyles}
            variant={smallScreen ? "temporary" : "permanent"}
            anchor="left"
            open={drawerOpen}
            PaperProps={{ elevation: 0 }}
            ModalProps={{ onBackdropClick: handleDrawerClose }}
          >
            {smallScreen ? (
              <>
                <IconButton onClick={handleDrawerClose} sx={closeNavStyles}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </IconButton>
                <Divider />
              </>
            ) : (
              <>
                <Box sx={partnerLogoStyles}>
                  <NavLink to="/">
                    <PartnerLogoFull />
                  </NavLink>
                </Box>
              </>
            )}
            <HelmetProvider>
              <List>
                {userLoading ? (
                  <ListItem
                    button
                    sx={navItemStyles}
                    onClick={() => setLogoutConfirmOpen(true)}
                  >
                    <ListItemIcon sx={navIconStyles}>
                      <FontAwesomeIcon icon={faSignOutAlt} />
                    </ListItemIcon>
                    <ListItemText primary={"Log out"} />
                  </ListItem>
                ) : (
                  // <TrailFanLeftFadeIn>
                  <>{navItemsMemoized}</>
                )}
              </List>
            </HelmetProvider>
            <List sx={footerStyles}>
              <ListItem sx={centerContentStyles}>
                <TpsLogo />
              </ListItem>
            </List>
          </Drawer>
          <Box component="main" sx={commonStyles.contentBox}>
            <Outlet />
          </Box>
        </Box>
      </Box>

      {/* Logout confirmation */}
      <Dialog open={logoutConfirmOpen} onClose={closeLogoutConfirm}>
        <DialogTitle>Logout</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to log out?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeLogoutConfirm}>Cancel</Button>
          <Button variant="contained" onClick={logout}>
            Logout
          </Button>
        </DialogActions>
      </Dialog>

      {/* Privacy Agreement */}
      <Dialog
        open={
          userQuery !== undefined &&
          !userQuery?.isLoading &&
          privacyAgreementOpen &&
          privacyStatementOutOfDate
        }
        sx={{ backdropFilter: "blur(24px)" }}
      >
        <DialogTitle>Student Data Privacy Agreement</DialogTitle>
        <DialogContent>
          <Typography>
            You are accessing confidential and sensitive student data that is
            protected under the Family Educational Rights and Privacy Act
            (FERPA). This information may only be discussed with, viewed by, or
            shared with individuals within your organization as stipulated by
            your data sharing agreement (DSA) with Tacoma Public Schools. Thank
            you for partnering with Tacoma Public Schools to honor and support
            Tacoma’s students and their families.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={submitPrivacyAgreement}
            disabled={upsertUserSettings.isLoading}
          >
            I AGREE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
