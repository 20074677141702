import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useUser } from "../../services/contexts/userContext";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faInfoCircle,
  faUserPen,
} from "@fortawesome/pro-solid-svg-icons";
import dayjs from "dayjs";
import { useGetPartnerDsaDates } from "../../services/hooks/partnersHook";
import HomeChartDsaGenderRace from "./HomeChartDsaGenderRace";
import { useGetDsaHomeDataCounts } from "../../services/hooks/partnerHomeHook";

export default function HomeDsa() {
  const { user } = useUser();
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const navigate = useNavigate();

  const dsaQuery = useGetPartnerDsaDates(
    user?.partners?.[0] ? user?.partners?.[0] : -1
  );
  const dsaData = dsaQuery?.data?.data;

  const query = useGetDsaHomeDataCounts();
  const data = query?.data?.data?.races;
  const totalStudents = query?.data?.data?.totalStudents;
  const criteriaData = query?.data?.data?.criteriaData;

  //#region Styles
  const wrap = {
    minHeight: "calc(100vh - 124px)", //viewport height - page title height and main vertical padding
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    mx: 3,
  };
  //#endregion

  // MARK: Render
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ ...commonStyles.paperPadding, marginTop: 5 }}>
          <Typography
            component="h2"
            variant="h3"
            sx={{ fontWeight: 300, mb: 2 }}
          >
            <em>Howdy, partner!</em>
          </Typography>
          {dayjs(dayjs().format("YYYY-MM-DD")).isBetween(
            dayjs(dsaData?.dsaStartDate),
            dayjs(dsaData?.dsaEndDate),
            null,
            "[]"
          ) ? (
            <>
              <Typography sx={{ mb: 1 }}>
                Your Data Sharing Agreement (DSA) with Tacoma Public Schools is
                active{" "}
                <strong>
                  {dayjs(dsaData?.dsaStartDate).format("M/D/YY")} -{" "}
                  {dayjs(dsaData?.dsaEndDate).format("M/D/YY")}
                </strong>
                . You can now access student data for{" "}
                <strong>{totalStudents}</strong> students meeting the following
                criteria:
              </Typography>
              <ul>
                {criteriaData?.map((data) => {
                  return (
                    <li>
                      <Typography>
                        Students from{" "}
                        {data.schools.includes("All Schools")
                          ? "all schools"
                          : data.schools.join(", ")}{" "}
                        in{" "}
                        {data.grades.includes("All Grades")
                          ? "all grades"
                          : data.grades
                              .map((grade) =>
                                [
                                  "1st",
                                  "2nd",
                                  "3rd",
                                  "4th",
                                  "5th",
                                  "6th",
                                  "7th",
                                  "8th",
                                  "9th",
                                  "10th",
                                  "11th",
                                  "12th",
                                ].includes(grade)
                                  ? grade.concat(" grade")
                                  : grade
                              )
                              .join(", ")
                              .toLowerCase()}{" "}
                        and in{" "}
                        {data.programs.includes("All Programs")
                          ? "all programs."
                          : data.programs
                              .join(", ")
                              .concat(
                                data.programs.length > 1
                                  ? " programs."
                                  : " program."
                              )}
                      </Typography>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            <Typography>
              Your Data Sharing Agreement (DSA) with Tacoma Public Schools is
              not active. Starts {dayjs(dsaData?.dsaStartDate).format("M/D/YY")}{" "}
              and ends {dayjs(dsaData?.dsaEndDate).format("M/D/YY")}
            </Typography>
          )}
        </Paper>
      </Grid>
      {dayjs(dayjs().format("YYYY-MM-DD")).isBetween(
        dsaData?.dsaStartDate,
        dsaData?.dsaEndDate,
        "day", // granularity
        [] // inclusivity of start and end dates
      ) && (
        <Grid item xs={12}>
          <HomeChartDsaGenderRace data={data} />
        </Grid>
      )}
      <Grid item xs={12} md={7} sx={{ display: "flex" }}>
        <Card sx={{ flexGrow: 1, display: "flex" }}>
          <CardActionArea
            sx={{ flexGrow: 1, display: "flex", alignItems: "flex-start" }}
            href="/reports/instructor"
            disabled={
              !dayjs(dayjs().format("YYYY-MM-DD")).isBetween(
                dsaData?.dsaStartDate,
                dsaData?.dsaEndDate,
                "day", // granularity
                [] // inclusivity of start and end dates
              )
            }
          >
            <CardContent
              sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
            >
              {!dayjs(dayjs().format("YYYY-MM-DD")).isBetween(
                dsaData?.dsaStartDate,
                dsaData?.dsaEndDate,
                "day", // granularity
                [] // inclusivity of start and end dates
              ) && (
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  color={theme?.palette?.primary?.light}
                  sx={{ textAlign: "center" }}
                >
                  DSA Agreement is not&nbsp;active.
                </Typography>
              )}
              <Typography
                variant="h4"
                component="h3"
                gutterBottom
                color={theme?.palette?.primary?.main}
              >
                View Reports...
              </Typography>
              <Grid
                container
                spacing={0}
                wrap="nowrap"
                sx={{ flexDirection: "column" }}
              >
                <Grid item>
                  <Typography color="textSecondary" component="p" gutterBottom>
                    Peruse tables of student data on attendance, grades,
                    demographics,&nbsp;etc.
                  </Typography>
                  <Typography color="textSecondary" component="p" gutterBottom>
                    <em>
                      This is where you find the data you need to
                      make&nbsp;decisions...
                    </em>
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    flexGrow: 1,
                    textAlign: "right",
                  }}
                >
                  <Typography>
                    <FontAwesomeIcon
                      icon={faChartLine}
                      size="7x"
                      color={theme?.palette?.primary?.main}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} md={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* <Grid container spacing={3}>
              <Grid item xs={12}> */}
            <Card>
              <CardActionArea href="/resources">
                <CardContent>
                  <Typography
                    variant="h5"
                    component="h3"
                    gutterBottom
                    color={theme?.palette?.secondary?.main}
                  >
                    Find Resources
                  </Typography>
                  <Grid container spacing={3} wrap="nowrap">
                    <Grid item sx={{ flexGrow: 3 }}>
                      <Typography color="textSecondary" component="p">
                        A few resources which help our partnerships&nbsp;thrive.
                      </Typography>
                    </Grid>
                    <Grid item sx={{ flexGrow: 1, textAlign: "right" }}>
                      <Typography>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          size="3x"
                          color={theme?.palette?.secondary?.main}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardActionArea href="/personalization">
                <CardContent>
                  <Typography
                    variant="h5"
                    component="h3"
                    gutterBottom
                    color={theme?.palette?.secondary?.main}
                  >
                    Personalize this app
                  </Typography>
                  <Grid container spacing={3} wrap="nowrap">
                    <Grid item sx={{ flexGrow: 3 }}>
                      <Typography color="textSecondary" component="p">
                        Pick a theme to customize your&nbsp;view.
                      </Typography>
                    </Grid>
                    <Grid item sx={{ flexGrow: 1, textAlign: "right" }}>
                      <Typography>
                        <FontAwesomeIcon
                          icon={faUserPen}
                          size="3x"
                          color={theme?.palette?.secondary?.main}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
            {/* </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
