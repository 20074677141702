import { useState } from "react";
import {
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import EChartsReact from "echarts-for-react";

export default function ChartGradeCounts({ status, slicerData }) {
  const commonStyles = useCommonStyles();
  const theme = useTheme();
  const [countSort, setCountSort] = useState(false);

  const data = slicerData?.sort((a, b) => {
    if (countSort) {
      return b.students - a.students;
    }
    return a.grade?.localeCompare(b.grade);
  });

  const chartOptions = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    xAxis: {
      type: "category",
      data: data?.map((d) => d.grade),
      minInterval: 1,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: data?.map((d) => d.students),
        type: "bar",
        color: status
          ? theme.palette.text.secondary
          : theme.palette.chart && theme.palette.chart[0],
      },
    ],
  };

  return (
    <Paper sx={commonStyles.paperPadding}>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" color={theme.palette.text.secondary}>
          Students by Grade
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Alphabetical</Typography>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={countSort}
                onChange={(e) => {
                  setCountSort(e.target.checked);
                }}
              />
            }
          />
          <Typography>Count</Typography>
        </Stack>
      </Stack>

      <EChartsReact option={chartOptions} />
    </Paper>
  );
}
