import { Grid, Stack } from "@mui/material";
import { useOutletContext } from "react-router";
import ChartLocationCounts from "./ChartLocationCounts";
import ChartActivityCounts from "./ChartActivityCounts";
import ChartActivityTypeCounts from "./ChartActivityTypeCounts";
import ChartProviderCounts from "./ChartProviderCounts";
import ChartTopActivityParticipation from "./ChartTopActivityParticipation";
import ReportSummary from "./ReportSummary";
import { useMemo } from "react";
import { useGetProgramReport } from "../../services/hooks/partnerReportsHook";

export default function ReportsPrograms() {
  const [slicers] = useOutletContext();

  const query = useGetProgramReport(slicers);
  const data = query?.data?.data;

  const slicerData = useMemo(() => {
    return data ? data : {};
  }, [data, slicers]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReportSummary status={query?.isLoading} slicerData={slicerData} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <ChartLocationCounts
              status={query?.isLoading}
              slicerData={slicerData?.locations}
            />
            <ChartProviderCounts
              status={query?.isLoading}
              slicerData={slicerData?.providers}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <ChartActivityTypeCounts
              status={query?.isLoading}
              slicerData={slicerData?.activitiesType}
            />
            <ChartActivityCounts
              status={query?.isLoading}
              slicerData={slicerData?.activities}
            />
            <ChartTopActivityParticipation
              status={query?.isLoading}
              slicerData={
                slicerData?.activities
                  ? slicerData?.activities
                      .sort((a, b) => {
                        return b.programs - a.programs;
                      })
                      .slice(0, 10)
                  : []
              }
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
