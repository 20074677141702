import React, { useState, useMemo } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useGetProgramsList } from "../../services/hooks/programsHook";
import { useNavigate } from "react-router-dom";
import useSessionStorage from "../../services/hooks/useSessionStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { DraftPrograms, ManagePrograms } from "../../services/permissions";
import { useUser } from "../../services/contexts/userContext";
import ProgramTable from "../common/ProgramTable";
import { ScrollToTopOnMount } from "../../services/utilities";

export default function Programs() {
  const { user } = useUser();
  const theme = useTheme();
  const navigate = useNavigate();

  const [selectedStatuses, setSelectedStatuses] = useSessionStorage(
    "programsListStatus",
    ["current", "upcoming", "draft"]
  );
  const [searchInput, setSearchInput] = useState("");
  const [selectedLocations, setSelectedLocations] = useSessionStorage(
    "programsListLocations",
    []
  );

  const programsQuery = useGetProgramsList(selectedStatuses.join(","));
  const filteredPrograms = useMemo(() => {
    let copyPrograms = programsQuery?.data?.data;

    if (searchInput?.length > 0) {
      copyPrograms = copyPrograms?.filter((program) => {
        return (
          program.title?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
          program.activityName
            ?.toLowerCase()
            .includes(searchInput?.toLowerCase()) ||
          program.activityTypeName
            ?.toLowerCase()
            .includes(searchInput?.toLowerCase()) ||
          program.provider?.toLowerCase().includes(searchInput?.toLowerCase())
        );
      });
    }
    if (selectedLocations?.length > 0) {
      copyPrograms = copyPrograms?.filter((program) => {
        return selectedLocations?.some((l) => l === program.locationId);
      });
    }
    return copyPrograms;
  }, [programsQuery, searchInput, selectedLocations]);

  const uniqueLocations = useMemo(() => {
    return programsQuery?.data?.data?.reduce((acc, curr) => {
      if (!acc.some((a) => a.locationId === curr.locationId)) {
        acc.push({
          locationId: curr.locationId,
          locationName: curr.locationName,
        });
      }
      return acc?.sort((a, b) => {
        return a.locationName.localeCompare(b.locationName);
      });
    }, []);
  }, [programsQuery]);

  return (
    <Box>
      <ScrollToTopOnMount />
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h1">Programs</Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Programs are like classes. They occur during a session, have start and end dates, have a group of participants, and track attedance.">
                <IconButton>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        {(user?.permissions?.includes(ManagePrograms) ||
          user?.permissions?.includes(DraftPrograms)) && (
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/programs/create");
              }}
              startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
            >
              Create Program
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} sx={{ my: theme.spacing(2) }}>
        {/* Search bar */}
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <TextField
              value={searchInput ?? ""}
              id="program-search"
              label="Search"
              variant="outlined"
              inputProps={{
                autoComplete: "off",
              }}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
          </FormControl>
        </Grid>

        {/* Status Picker */}
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="Status-label">Status</InputLabel>
            <Select
              label="Status"
              labelId="Status-label"
              margin="dense"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              value={selectedStatuses ?? []}
              onChange={(e) => {
                setSelectedStatuses(e.target.value);
              }}
              multiple
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Status</em>;
                }
                return selectedStatuses
                  ?.map((sl) => sl.charAt(0).toUpperCase() + sl.slice(1))
                  ?.join(", ");
              }}
            >
              <MenuItem value={"disabled"} disabled>
                Status
              </MenuItem>

              <MenuItem value={"current"}>
                <Checkbox checked={selectedStatuses?.indexOf("current") > -1} />
                <ListItemText>Current</ListItemText>
              </MenuItem>
              <MenuItem value={"upcoming"}>
                <Checkbox
                  checked={selectedStatuses?.indexOf("upcoming") > -1}
                />
                <ListItemText>Upcoming</ListItemText>
              </MenuItem>
              <MenuItem value={"draft"}>
                <Checkbox checked={selectedStatuses?.indexOf("draft") > -1} />
                <ListItemText>Draft</ListItemText>
              </MenuItem>
              <MenuItem value={"past"}>
                <Checkbox checked={selectedStatuses?.indexOf("past") > -1} />
                <ListItemText>Past</ListItemText>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Location Picker */}
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            disabled={programsQuery?.isLoading}
            value={
              uniqueLocations?.filter((ul) =>
                selectedLocations?.includes(ul.locationId)
              ) ?? []
            }
            onChange={(event, value) => {
              setSelectedLocations(value.map((v) => v.locationId ?? v));
            }}
            options={uniqueLocations ?? []}
            getOptionLabel={(option) => option.locationName}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                {option.locationName}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Locations" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  color="primary"
                  variant="contained"
                  label={option.locationName}
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </Grid>
      </Grid>

      <ProgramTable programs={filteredPrograms} query={programsQuery} />
    </Box>
  );
}
