import { useState, useMemo, useEffect } from "react";
import {
  CircularProgress,
  Link,
  Paper,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import {
  useGetAttendanceData,
  useGetAttendanceDataTotalRowCount,
} from "../../services/hooks/partnerReportsHook";
import ParticipantDetails from "../common/ParticipantDetails";

export default function TableAttendance({ slicers }) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [inspectParticipant, setInspectParticipant] = useState(null);
  const [percentComplete, setPercentComplete] = useState(0);
  const [finished, setFinished] = useState(false);
  const [showName, setShowName] = useState(false);
  const [columns, setColumns] = useState([
    { field: "studentId", headerName: "ID", width: 100 },
    {
      field: "school",
      headerName: "School",
      width: 120,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "grade",
      headerName: "Grade",
      width: 60,
      align: "center",
    },
    { field: "gender", headerName: "Gender", width: 70, align: "center" },
    {
      field: "race",
      headerName: "Race",
      width: 160,
      minWidth: 90,
      flex: showName ? 0 : 1,
    },
    {
      field: "schoolYear",
      headerName: "School Year",
      width: 110,
      align: "center",
    },
    {
      field: "unexcused",
      headerName: "% Unexcused",
      width: 110,
      align: "left",
      valueFormatter: (params) => {
        if (params.value === null) {
          return "";
        }
        const val = params.value * 100;
        return `${val.toFixed(2)}%`;
      },
    },
    {
      field: "excused",
      headerName: "% Excused",
      width: 110,
      align: "left",
      valueFormatter: (params) => {
        if (params.value === null) {
          return "";
        }
        const val = params.value * 100;
        return `${val.toFixed(2)}%`;
      },
    },
    {
      field: "tardy",
      headerName: "% Tardy",
      width: 110,
      align: "left",
      valueFormatter: (params) => {
        if (params.value === null) {
          return "";
        }
        const val = params.value * 100;
        return `${val.toFixed(2)}%`;
      },
    },
  ]);

  const gridStyles = {
    border: "none",
    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle":
      // allowing for wrapping of long column header titles
      {
        lineHeight: "1rem",
        whiteSpace: "normal",
      },
    "& .MuiDataGrid-columnHeader:last-of-type": {
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
    },
  };

  const queryRowCount = useGetAttendanceDataTotalRowCount(slicers);
  const dataRowCount = useMemo(() => {
    return queryRowCount?.status === "success" ? queryRowCount?.data?.data : 0;
  }, [queryRowCount]);

  const queryAttendanceData = useGetAttendanceData(slicers, dataRowCount);

  const attendanceData = useMemo(() => {
    if (queryAttendanceData?.queries.length > 0) {
      return queryAttendanceData?.queries
        ?.map((data) => {
          return data?.isSuccess ? data?.data?.data : [];
        })
        .flat()
        .map((data, i) => {
          return { ...data, id: i };
        });
    } else {
      return [];
    }
  }, [queryAttendanceData]);

  useEffect(() => {
    if (queryAttendanceData?.percentComplete) {
      setPercentComplete(queryAttendanceData.percentComplete);
    }
    if (!queryRowCount.isLoading && dataRowCount === 0) {
      setFinished(true);
    } else {
      setFinished(
        queryAttendanceData?.finished && queryAttendanceData?.finished
      );
    }
  }, [queryAttendanceData, queryRowCount, dataRowCount]);

  useEffect(() => {
    if (attendanceData) {
      setShowName(
        attendanceData?.some(
          (data) => data?.studentName !== "" && data?.studentName !== null
        )
      );
    }
  }, [attendanceData]);

  useEffect(() => {
    if (showName && !columns.some((col) => col.field === "studentName")) {
      const editColumns = [...columns];
      editColumns.splice(1, 0, {
        field: "studentName",
        headerName: "Name",
        width: 180,
        minWidth: 100,
        flex: 1,
        renderCell: (val) => {
          return (
            <Link
              onClick={() => {
                setInspectParticipant(val.row.participantId);
              }}
              sx={{ cursor: "pointer" }}
            >
              <strong>{val.formattedValue}</strong>
            </Link>
          );
        },
      });
      setColumns(editColumns);
    }
  }, [showName]);

  return (
    <>
      {/* Participant details dialog */}
      {inspectParticipant && (
        <ParticipantDetails
          participantId={inspectParticipant}
          onClose={() => {
            setInspectParticipant(null);
          }}
        />
      )}
      <Typography variant="h4" component="h3">
        School Attendance&nbsp;
      </Typography>
      <Paper>
        <DataGridPremium
          rows={attendanceData.filter(
            (row) => row !== undefined && row !== null
          )}
          // getRowId={(row) =>
          //   `${row?.studentId} ${row?.studentName} ${row?.schoolYear}`
          // }
          columns={columns}
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{
            sorting: {
              sortModel: [{ field: "studentName", sort: "asc" }],
            },
          }}
          disableRowSelectionOnClick={true}
          autoHeight
          columnHeaderHeight={64}
          sx={gridStyles}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          loading={!finished}
          slots={{
            loadingOverlay: () => {
              return (
                <>
                  {percentComplete === 0 ? (
                    <LinearProgress />
                  ) : (
                    <LinearProgress
                      variant="determinate"
                      value={percentComplete}
                    />
                  )}
                  <div style={{ position: "relative" }}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "calc(50% + 50px)",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        display: "inline-flex",
                      }}
                    >
                      {percentComplete === 0 ? (
                        <CircularProgress />
                      ) : (
                        <CircularProgress
                          variant="determinate"
                          value={percentComplete}
                        />
                      )}
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          component="div"
                          color="text.secondary"
                        >
                          {`${percentComplete}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                </>
              );
            },
            noRowsOverlay: () => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%", // adjust as needed
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    color="textSecondary"
                    sx={{ p: 2 }}
                  >
                    {queryAttendanceData?.errors ? (
                      <>{"We encountered some errors. "}</>
                    ) : (
                      "No data"
                    )}
                  </Typography>
                </Box>
              );
            },
          }}
        />
      </Paper>
    </>
  );
}
