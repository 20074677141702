import React, { useState, useMemo } from "react";
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useGetActivities } from "../../services/hooks/activitiesHook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCirclePlus,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Error from "../common/Error";
import { getComparator, stableSort } from "../../services/tableFunctions";
import { useUser } from "../../services/contexts/userContext";
import { ManageActivities } from "../../services/permissions";
import useLocalStorage from "../../services/hooks/useLocalStorage";
import useSessionStorage from "../../services/hooks/useSessionStorage";
import { ScrollToTopOnMount } from "../../services/utilities";

export default function Activities() {
  const { user } = useUser();
  const theme = useTheme();
  const navigate = useNavigate();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("activityName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useSessionStorage(
    "activityListRowsPerPage",
    10
  );
  const [searchInput, setSearchInput] = useState("");
  const [showInactive, setShowInactive] = useLocalStorage(
    "activitiesListShowInactive",
    false
  );

  const filterActivities = (acts, search) => {
    let copyActivities = acts?.data?.data;
    if (search?.length > 0) {
      copyActivities = copyActivities?.filter((activity) => {
        return (
          activity.activityName
            ?.toLowerCase()
            .includes(search?.toLowerCase()) ||
          activity.activityType?.activityTypeName
            ?.toLowerCase()
            .includes(search?.toLowerCase())
        );
      });
    }
    if (!showInactive) {
      copyActivities = copyActivities?.filter((activity) => activity.active);
    }

    return copyActivities;
  };

  const activitiesQuery = useGetActivities();

  const filteredActivities = useMemo(
    () => filterActivities(activitiesQuery, searchInput),
    [activitiesQuery, searchInput]
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headCells = [
    {
      id: "activityName",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "activityTypeName",
      numeric: false,
      disablePadding: false,
      label: "Type",
    },
    {
      id: "activityDescription",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "active",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography>{headCell.label}</Typography>
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  //Styles
  const descriptionStyles = {
    color: theme.palette.text.secondary,
    fontSize: "0.8rem",
  };
  const inactiveStyles = {
    background: theme.palette.background.default,
  };
  const tableTopStyles = {
    padding: theme.spacing(2),
  };
  const tableRowStyles = {
    cursor: user?.permissions?.includes(ManageActivities) && "pointer",
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Grid container justifyContent={"space-between"} alignItems="center">
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h1">Activities</Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Activities are generic descriptions that help categorize programs for data reporting purposes. There are many Tennis programs, but only one Tennis activity.">
                <IconButton>
                  <FontAwesomeIcon size="sm" icon={faInfoCircle} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item></Grid>
        {user?.permissions?.includes(ManageActivities) && (
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/activities/create");
              }}
              startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
            >
              Create Activity
            </Button>
          </Grid>
        )}
      </Grid>

      <br />

      <Error
        message="There was a problem loading activities."
        query={activitiesQuery}
      />

      <Paper>
        <Grid container spacing={2} sx={tableTopStyles} alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              value={searchInput}
              id="activity-search"
              label="Search"
              variant="outlined"
              onChange={(e) => {
                setSearchInput(e.target.value);
                handleChangePage(e, 0);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={showInactive}
                  onChange={(e) => {
                    setShowInactive(e.target.checked);
                    setPage(0);
                  }}
                />
              }
              label="Show Inactive"
            />
          </Grid>
        </Grid>
        <TableContainer>
          <Table aria-label="activities table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredActivities?.length ?? 0}
            />
            <TableBody>
              {activitiesQuery?.isLoading &&
                Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <TableRow key={index}>
                      <TableCell colSpan="5">
                        <Skeleton variant="rect" height={60} />
                      </TableCell>
                    </TableRow>
                  ))}
              {filteredActivities &&
                stableSort(filteredActivities, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((activity, index) => (
                    <TableRow
                      key={index}
                      hover={true}
                      sx={[tableRowStyles, !activity.active && inactiveStyles]}
                      onClick={() => {
                        if (user?.permissions?.includes(ManageActivities))
                          navigate(`/activities/edit/${activity.activityId}`);
                      }}
                    >
                      <TableCell>{activity.activityName}</TableCell>
                      <TableCell>
                        {activity.activityType?.activityTypeName}
                      </TableCell>
                      <TableCell sx={descriptionStyles}>
                        {activity.activityDescription}
                      </TableCell>
                      <TableCell>
                        {activity.active ? "Active" : "Inactive"}
                      </TableCell>
                      <TableCell>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={
            filteredActivities?.length === undefined
              ? 0
              : filteredActivities?.length
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
