import React from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Paper,
  Typography,
  Link,
} from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { useUser } from "../../services/contexts/userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { ScrollToTopOnMount } from "../../services/utilities";

export default function Resources() {
  const user = useUser();
  const role = user?.user?.role;
  const theme = useTheme();

  const resources = [
    {
      title: <span>Partnership Office</span>,
      description: (
        <span>
          Fostering a cooperative relationship between students, families,
          schools, district and the greater Tacoma community. Volunteers are a
          key part...
        </span>
      ),
      links: [
        {
          text: "",
          url: "https://www.tacomaschools.org/departments/community-partnerships",
        },
        {
          text: "Get involved",
          url: "https://www.tacomaschools.org/departments/community-partnerships",
        },
      ],
    },
    {
      title: <span>Social Emotional Learning Signature Practices</span>,
      description: (
        <span>
          Tacoma’s 3 Signature Practices are research-based strategies
          implemented by all adults in an effort to provide inclusive
          opportunities for youth in feeling safe and supported as they navigate
          throughout their day.
        </span>
      ),
      links: [
        {
          text: "",
          url: "resources/sel",
        },
        {
          text: "Social Emotional Learning",
          url: "resources/sel",
        },
      ],
    },
    {
      title: <span>Safe Schools Training</span>,
      description: (
        <span>
          We make student safety a high priority. Complete required training in
          order to work with students.
        </span>
      ),
      links: [
        { text: "", url: "http://tacoma.wa.safeschools.com/training/home" },
        {
          text: "Employees",
          url: "http://tacoma.wa.safeschools.com/training/home",
        },
        {
          text: "Partners",
          url: "https://tacomavolunteers-wa.safeschools.com/login",
        },
      ],
    },
    {
      title: <span>School Board partnership&nbsp;docs</span>,
      description: (
        <span>
          The official policy and regulation for Community Partnerships and
          Commercial Activities.
        </span>
      ),
      links: [
        {
          text: "",
          url: "https://www.tacomaschools.org/about/school-board/policy-manual/policy-details-page/~board/policy-4000/post/4140r",
        },
        {
          text: "Policy",
          url: "https://www.tacomaschools.org/about/school-board/policy-manual/policy-details-page/~board/policy-4000/post/4140",
        },
        {
          text: "Regulation",
          url: "https://www.tacomaschools.org/about/school-board/policy-manual/policy-details-page/~board/policy-4000/post/4140r",
        },
      ],
    },
    {
      title: <span>TPS Calendar</span>,
      description: (
        <span>
          Help coordinate partner events around existing TPS events and
          no-school days.
        </span>
      ),
      links: [
        { text: "", url: "https://www.tacomaschools.org/about/calendar" },
        {
          text: "Calendar",
          url: "https://www.tacomaschools.org/about/calendar",
        },
      ],
    },
    {
      title: <span>Strategic&nbsp;Plan &amp; Benchmarks</span>,
      description: (
        <span>
          See how we are doing working as we work toward strategic goals in{" "}
          <em>Academic Excellence</em>, <em>Partnerships</em>,{" "}
          <em>Early Learning</em> and <em>Safety</em>.
        </span>
      ),
      links: [
        { text: "", url: "https://www.tacomaschools.org/about/strategic-plan" },
        {
          text: "Benchmarks",
          url: "https://www.tacomaschools.org/about/strategic-plan",
        },
      ],
    },
  ];

  return (
    <>
      <ScrollToTopOnMount />
      <Typography variant="h1">Resources</Typography>
      <>
        <Grid container>
          <Grid container>
            <Grid item xs={12}>
              <Typography paragraph variant="subtitle1">
                Find links to policies, regulations and other foundational
                resources which help our partnerships thrive.
              </Typography>
            </Grid>
          </Grid>
          {role?.toLowerCase() !== "dsa viewer" && (
            <Grid container spacing={2} alignItems="stretch">
              <Grid item sm={12} md={6} lg={4}>
                <Paper elevation={2} sx={{ mb: 2 }}>
                  <Box
                    sx={{
                      width: "100%",
                      //height: 300,
                      backgroundColor: theme.palette.primary.main,
                      p: 2,
                    }}
                  >
                    <Typography
                      component="p"
                      variant="h5"
                      sx={{
                        color: theme.palette.primary.contrastText,
                      }}
                    >
                      Instructors &amp; Coaches
                    </Typography>
                  </Box>
                  <Box sx={{ width: "100%", p: 2 }}>
                    <Divider>
                      <Typography component="h2" variant="h5">
                        Partner Portal FAQs
                      </Typography>
                    </Divider>
                    <Typography component="div" variant="body1">
                      How do I take student attendance?
                    </Typography>
                    <Typography component="div" variant="body1">
                      How do I access student emergency contacts?
                    </Typography>
                    <Typography component="div" variant="body1">
                      How do I log into the portal?
                    </Typography>
                    <Typography component="div" variant="body1">
                      Compass (Guardian Access)
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="https://app.smartsheet.com/b/publish?EQBCT=a47a1d1119e94d4fb79bc06a49494019"
                      target="_blank"
                      sx={{ my: 1 }}
                    >
                      <FontAwesomeIcon icon={faLink} />
                      &nbsp;Partner&nbsp;Portal&nbsp;FAQs
                    </Button>
                    <Divider>
                      <Typography component="h2" variant="h5">
                        Program Resources &amp; FAQs
                      </Typography>
                    </Divider>
                    <Typography component="p" variant="body1">
                      What are my responsibilities as an instructor?
                    </Typography>
                    <Typography component="p" variant="body1">
                      What is Social Emotional Learning (SEL)?
                    </Typography>
                    <Typography component="p" variant="body1">
                      How do I manage my classroom?
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="https://metroparkstacoma.sharepoint.com/sites/BeyondtheBell/SitePages/ProjectHome.aspx"
                      target="_blank"
                      sx={{ my: 1 }}
                    >
                      <FontAwesomeIcon icon={faLink} />
                      &nbsp;Program&nbsp;Resources&nbsp;&amp;&nbsp;FAQs
                    </Button>
                    <Divider>
                      <Typography component="h2" variant="h5">
                        Cancellations
                      </Typography>
                    </Divider>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="https://app.smartsheet.com/b/publish?EQBCT=a47a1d1119e94d4fb79bc06a49494019"
                      target="_blank"
                      sx={{ my: 1 }}
                    >
                      <FontAwesomeIcon icon={faLink} />
                      &nbsp;Day-of-Program&nbsp;Cancellation
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <Paper elevation={2} sx={{ mb: 2 }}>
                  <Box
                    sx={{
                      width: "100%",
                      //height: 300,
                      backgroundColor: theme.palette.secondary.main,
                      p: 2,
                    }}
                  >
                    <Typography
                      component="div"
                      variant="h5"
                      sx={{
                        color: theme.palette.secondary.contrastText,
                      }}
                    >
                      Site Leads
                    </Typography>
                  </Box>
                  <Box sx={{ width: "100%", p: 2 }}>
                    <Divider>
                      <Typography component="h2" variant="h5">
                        Partner&nbsp;Portal&nbsp;FAQs
                      </Typography>
                    </Divider>
                    <Typography component="div" variant="body1">
                      How to take student attendance?
                    </Typography>
                    <Typography component="div" variant="body1">
                      How do I access student emergency contacts?
                    </Typography>
                    <Typography component="div" variant="body1">
                      How do I log into the portal?
                    </Typography>
                    <Typography component="div" variant="body1">
                      Compass (Guardian Access)
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="https://app.smartsheet.com/b/publish?EQBCT=3af2a13f02da4707889ff61483928d80"
                      target="_blank"
                      sx={{ my: 1 }}
                    >
                      <FontAwesomeIcon icon={faLink} />
                      &nbsp;Partner&nbsp;Portal&nbsp;FAQs
                    </Button>
                    <Divider>
                      <Typography component="h2" variant="h5">
                        Program Resources &amp; FAQs
                      </Typography>
                    </Divider>
                    <Typography component="p" variant="body1">
                      What are my responsibilities of an instructor?
                    </Typography>
                    <Typography component="p" variant="body1">
                      What is Social Emotional Learning (SEL)?
                    </Typography>
                    <Typography component="p" variant="body1">
                      How do I help an instructor manage their classroom?
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="https://metroparkstacoma.sharepoint.com/sites/BeyondtheBell/SitePages/ProjectHome.aspx"
                      target="_blank"
                      sx={{ my: 1 }}
                    >
                      <FontAwesomeIcon icon={faLink} />
                      &nbsp;Program&nbsp;Resources&nbsp;&amp;&nbsp;FAQs
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="https://metroparkstacoma.sharepoint.com/sites/BeyondtheBellSiteLead2022-2023"
                      target="_blank"
                      sx={{ my: 1 }}
                    >
                      <FontAwesomeIcon icon={faLink} />
                      &nbsp;MPT&nbsp;Beyond&nbsp;the Bell&nbsp;Communications
                    </Button>
                    <Divider>
                      <Typography component="h2" variant="h5">
                        Program Logistics
                      </Typography>
                    </Divider>
                    <Typography component="p" variant="body1">
                      Day-of-Program Cancellation Protocol
                    </Typography>
                    <Typography component="p" variant="body1">
                      Inclement Weather Protocol
                    </Typography>
                    <Typography component="p" variant="body1">
                      Student Addition/Withdraw Request
                    </Typography>
                    <Typography component="p" variant="body1">
                      Beyond the Bell Transportation Request
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="https://app.smartsheet.com/b/publish?EQBCT=3af2a13f02da4707889ff61483928d80"
                      target="_blank"
                      sx={{ my: 1 }}
                    >
                      <FontAwesomeIcon icon={faLink} />
                      &nbsp;Program&nbsp;Logistics
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <Paper elevation={2} sx={{ mb: 2 }}>
                  <Box
                    sx={{
                      width: "100%",
                      //height: 300,
                      backgroundColor: "nav.dark",
                      p: 2,
                    }}
                  >
                    <Typography
                      component="p"
                      variant="h5"
                      sx={{
                        color: theme.palette.nav.contrastText,
                      }}
                    >
                      Providers
                    </Typography>
                  </Box>
                  <Box sx={{ width: "100%", p: 2 }}>
                    <Divider>
                      <Typography component="h2" variant="h5">
                        Partner Portal FAQs
                      </Typography>
                    </Divider>
                    <Typography component="div" variant="body1">
                      How do I log in to the Portal?
                    </Typography>
                    <Typography component="div" variant="body1">
                      How do I view reports?
                    </Typography>
                    <Typography component="div" variant="body1">
                      Compass (Guardian Access)
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="https://app.smartsheet.com/b/publish?EQBCT=a47a1d1119e94d4fb79bc06a49494019"
                      target="_blank"
                      sx={{ my: 1 }}
                    >
                      <FontAwesomeIcon icon={faLink} />
                      &nbsp;Partner&nbsp;Portal&nbsp;FAQs
                    </Button>
                    <Divider>
                      <Typography component="h2" variant="h5">
                        Cancellations
                      </Typography>
                    </Divider>
                    <Typography component="p" variant="body1">
                      Full-Session Program Cancellation Protocol
                    </Typography>
                    <Typography component="p" variant="body1">
                      BtB Day-of-Program Cancellation Protocol
                    </Typography>
                    <Typography component="p" variant="body1">
                      Inclement Weather Protocol
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="https://app.smartsheet.com/b/publish?EQBCT=f3fa5ae95f324dd4b38ec5e667e256f2"
                      target="_blank"
                      sx={{ my: 1 }}
                    >
                      <FontAwesomeIcon icon={faLink} />
                      &nbsp;Cancellations
                    </Button>
                    <Divider>
                      <Typography component="h2" variant="h5">
                        Field Trips
                      </Typography>
                    </Divider>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="https://app.smartsheet.com/b/form/1231d037ea52464583081157f4772f9a"
                      target="_blank"
                      sx={{ my: 1 }}
                    >
                      <FontAwesomeIcon icon={faLink} />
                      &nbsp;Field&nbsp;Trip&nbsp;Logistics&nbsp;Request
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} alignItems="stretch">
            {resources.map((resource, i) => (
              <Grid item sm={12} md={6} lg={4} key={i}>
                <Card
                  elevation={2}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <CardActionArea
                    href={resource.links[0].url}
                    target={
                      resource.links[0].url.includes("http")
                        ? "_blank"
                        : "_self"
                    }
                    rel={
                      resource.links[0].url.includes("http") ? "noreferrer" : ""
                    }
                  >
                    <CardContent>
                      <Typography variant="h5" component="h3" gutterBottom>
                        {resource.title}
                      </Typography>
                      <Typography color="textSecondary" component="p">
                        {resource.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>

                  <CardActions>
                    <Button
                      variant="outlined"
                      color="primary"
                      href={resource.links[1].url}
                      target={
                        resource.links[1].url.includes("http")
                          ? "_blank"
                          : "_self"
                      }
                      rel={
                        resource.links[1].url.includes("http")
                          ? "noreferrer"
                          : ""
                      }
                    >
                      <FontAwesomeIcon icon={faLink} />
                      &nbsp;{resource.links[1].text}
                    </Button>
                    {resource.links[2] ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        href={resource.links[2].url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faLink} />
                        &nbsp;{resource.links[2].text}
                      </Button>
                    ) : (
                      ""
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}

            {/* <Grid item key={99999}>
              <Card
                elevation={2}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <CardContent>
                  <Typography variant="h5" component="h3" gutterBottom>
                    Program Cancellation Protocol
                  </Typography>
                  <Typography color="textSecondary" component="p" gutterBottom>
                    Communication protocol for cancellations will go into site
                    lead binders. MPT and Greentrike will ensure their providers
                    have been trained and informed. Site Leads are responsible
                    for ensuring their teams are informed of this protocol.
                  </Typography>
                  <Typography variant="h6" component="h3">
                    Step 1
                  </Typography>
                  <Typography color="textSecondary" component="p" gutterBottom>
                    ELO / Sport Provider is responsible to immediately send an
                    email notification to:{" "}
                  </Typography>
                  <Typography color="textSecondary" component="ul" gutterBottom>
                    <li>Your assigned Agency (GT or MPT)</li>
                    <li>
                      <Link
                        href="mailto:lgeorg3@tacoma.k12.wa.us"
                        target="_blank"
                      >
                        Lisa George
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="mailto:agannet@tacoma.k12.wa.us"
                        target="_blank"
                      >
                        AJ Gannett
                      </Link>
                    </li>
                    <li>Site Lead</li>
                  </Typography>
                  <Typography variant="h6" component="h3">
                    Step 2
                  </Typography>
                  <Typography color="textSecondary" component="p" gutterBottom>
                    ELO / Sport Provider is responsible to contact parents to
                    inform them the program is cancelled. You must receive
                    verbal or text confirmation from each parent.
                  </Typography>
                  <Typography variant="h6" component="h3">
                    Step 3
                  </Typography>
                  <Typography color="textSecondary" component="p" gutterBottom>
                    Send an email alert to <strong>Site Lead</strong>,{" "}
                    <strong>Agency Lead</strong>, <strong>Lisa George</strong>,
                    and <strong>AJ Gannett</strong> the status of parent
                    notifications.
                  </Typography>
                  <Typography color="textSecondary" component="ol" gutterBottom>
                    <li>Confirmed with parents: list name(s) of student(s)</li>
                    <li>
                      Not confirmed with parents: list of list name(s) of
                      student(s)
                    </li>
                  </Typography>
                  <Typography variant="h6" component="h3">
                    Step 4
                  </Typography>
                  <Typography color="textSecondary" component="p" gutterBottom>
                    Site Lead to notify school administration and Office Staff
                    of students confirmed and unconfirmed status.{" "}
                    <strong>Unconfirmed students cannot be sent home.</strong>
                  </Typography>
                  <Typography variant="h6" component="h3">
                    Step 5
                  </Typography>
                  <Typography color="textSecondary" component="p" gutterBottom>
                    Office staff to notify classroom teachers to not send
                    student to ELO at the end of the day.
                  </Typography>
                </CardContent>
              </Card>
            </Grid> */}
          </Grid>
        </Grid>
      </>
    </>
  );
}
