import { Grid } from "@mui/material";
import { useOutletContext } from "react-router";
import TableAssessments from "./TableAssessments";

export default function InstructorReportsAttendance() {
  const [slicers] = useOutletContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableAssessments slicers={slicers} />
      </Grid>
    </Grid>
  );
}
