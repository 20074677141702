import { Typography } from "@mui/material";
import { useSelectedTheme } from "./services/contexts/themeContext";
import { useUser } from "./services/contexts/userContext";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "react-day-picker/dist/style.css";
import {
  ViewPrograms,
  ManagePrograms,
  DraftPrograms,
  ViewActivities,
  ManageActivities,
  ViewUsers,
  ManageUsers,
  ViewProviders,
  ManageProviders,
  ViewPartners,
  ManagePartners,
  ViewRoster,
  ViewFRL,
  InstructPrograms,
  TakeAttendance,
  RegisterStudents,
} from "./services/permissions";
import { LicenseInfo } from '@mui/x-license';

//Components
import Nav from "./components/nav/Nav";
import Home from "./components/pages/Home";

import Activities from "./components/pages/Activities";
import ActivityCru from "./components/pages/ActivityCru";

import Programs from "./components/pages/Programs";
import ProgramDetails from "./components/pages/ProgramDetails";
import ProgramCru from "./components/pages/ProgramCru";

import Roster from "./components/pages/Roster";

import Reports from "./components/pages/Reports";
import ReportsPrograms from "./components/common/ReportsPrograms";
import ReportsDemographics from "./components/common/ReportsDemographics";
import ReportsAttendance from "./components/common/ReportsAttendance";
import InstructorReports from "./components/pages/InstructorReports";
import InstructorReportsDemographics from "./components/common/InstructorReportsDemographics";
import InstructorReportsAttendance from "./components/common/InstructorReportsAttendance";
import InstructorReportsEnrollment from "./components/common/InstructorReportsEnrollment";
import InstructorReportsAssessments from "./components/common/InstructorReportsAssessments";
import InstructorReportsDiscipline from "./components/common/InstructorReportsDiscipline";
import InstructorReportsMarks from "./components/common/InstructorReportsMarks";
import ReportsStudent from "./components/pages/ReportsStudent";

import Partners from "./components/pages/Partners";
import PartnerCru from "./components/pages/PartnerCru";

import Providers from "./components/pages/Providers";
import ProviderCru from "./components/pages/ProviderCru";

import Users from "./components/pages/Users";
import UserCru from "./components/pages/UserCru";

import Attendance from "./components/pages/Attendance";
import Resources from "./components/pages/Resources";
import Sel from "./components/pages/Sel";
import Personalization from "./components/pages/Personalization";

import LoadingUser from "./components/pages/LoadingUser";
import InvalidUser from "./components/pages/InvalidUser";
import Login from "./components/pages/Login";

function App() {
  const { selectedTheme } = useSelectedTheme();
  const { user, userQuery } = useUser();

  LicenseInfo.setLicenseKey('951b4853ae642bc3edf2946081963c4bTz04Mjg4MCxFPTE3Mzc3NDYxNDgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

  return (
    <ThemeProvider theme={responsiveFontSizes(createTheme(selectedTheme))}>
      <CssBaseline />
      <BrowserRouter>
        <AuthenticatedTemplate>
          {/* Loading User */}
          {userQuery?.isLoading && (
            <Routes>
              <Route path="*" element={<Nav />}>
                <Route
                  path="*"
                  element={<LoadingUser userQuery={userQuery} />}
                />
              </Route>
            </Routes>
          )}

          {/* Invalid User */}
          {userQuery?.isLoading == false && user?.validUser == false && (
            <Routes>
              <Route path="*" element={<Nav />}>
                <Route path="*" element={<InvalidUser />} />
              </Route>
            </Routes>
          )}

          {/* Normal Routes */}
          {userQuery?.isLoading == false && user?.validUser && (
            <Routes>
              <Route path="/" element={<Nav user={user} userQuery={userQuery} />}>
                <Route path="/" element={<Home />} />
                <Route path="home" element={<Home />} />
                <Route path="activities">
                  {user?.permissions?.includes(ViewActivities) && (
                    <Route path="" element={<Activities />} />
                  )}
                  {user?.permissions?.includes(ManageActivities) && (
                    <Route path="create" element={<ActivityCru />} />
                  )}
                  {user?.permissions?.includes(ManageActivities) && (
                    <Route path="edit/:activityId" element={<ActivityCru />} />
                  )}
                </Route>
                <Route path="programs">
                  <Route path="" element={<Programs />} />
                  {(user?.permissions?.includes(ManagePrograms) ||
                    user?.permissions?.includes(DraftPrograms)) && (
                    <Route path="create" element={<ProgramCru />} />
                  )}
                  {(user?.permissions?.includes(ManagePrograms) ||
                    user?.permissions?.includes(DraftPrograms)) && (
                    <Route path="edit/:programId" element={<ProgramCru />} />
                  )}
                  <Route path=":programId" element={<ProgramDetails />} />
                  <Route
                    path="attendance/:programId"
                    element={<Attendance />}
                  />
                </Route>
                {user?.permissions?.includes(ViewRoster) && (
                  <Route path="roster" element={<Roster />} />
                )}

                <Route
                  path="reports"
                  element={<Navigate to="program" replace />}
                />
                <Route path="reports/program" element={<Reports />}>
                  <Route path="" element={<Navigate to="programs" />} />
                  <Route path="programs" element={<ReportsPrograms />} />
                  <Route
                    path="demographics"
                    element={<ReportsDemographics />}
                  />
                  <Route path="attendance" element={<ReportsAttendance />} />
                </Route>
                <Route
                  path="reports/instructor"
                  element={<InstructorReports />}
                >
                  <Route
                    path=""
                    element={<Navigate to="demographics" replace />}
                  />
                  <Route
                    path="demographics"
                    element={<InstructorReportsDemographics />}
                  />
                  <Route
                    path="attendance"
                    element={<InstructorReportsAttendance />}
                  />
                  <Route
                    path="enrollment"
                    element={<InstructorReportsEnrollment />}
                  />
                  <Route
                    path="assessments"
                    element={<InstructorReportsAssessments />}
                  />
                  <Route
                    path="discipline"
                    element={<InstructorReportsDiscipline />}
                  />
                  <Route path="marks" element={<InstructorReportsMarks />} />
                </Route>
                <Route
                  path="reports/student/:participantId/"
                  element={<ReportsStudent />}
                >
                  <Route
                    path=""
                    element={<Navigate to="demographics" replace />}
                  />
                  <Route
                    path="demographics"
                    element={<InstructorReportsDemographics />}
                  />
                  <Route
                    path="attendance"
                    element={<InstructorReportsAttendance />}
                  />
                  <Route
                    path="enrollment"
                    element={<InstructorReportsEnrollment />}
                  />
                  <Route
                    path="assessments"
                    element={<InstructorReportsAssessments />}
                  />
                  <Route
                    path="discipline"
                    element={<InstructorReportsDiscipline />}
                  />
                  <Route path="marks" element={<InstructorReportsMarks />} />
                </Route>

                <Route path="users">
                  {user?.permissions?.includes(ViewUsers) && (
                    <Route path="" element={<Users />} />
                  )}
                  {user?.permissions?.includes(ManageUsers) && (
                    <Route path="create" element={<UserCru />} />
                  )}
                  {user?.permissions?.includes(ManageUsers) && (
                    <Route path="edit/:personId" element={<UserCru />} />
                  )}
                </Route>
                <Route path="providers">
                  {user?.permissions?.includes(ViewProviders) && (
                    <Route path="" element={<Providers />} />
                  )}
                  {user?.permissions?.includes(ManageProviders) && (
                    <Route path="create" element={<ProviderCru />} />
                  )}
                  {user?.permissions?.includes(ManageProviders) && (
                    <Route path="edit/:providerId" element={<ProviderCru />} />
                  )}
                </Route>
                <Route path="partners">
                  {user?.permissions?.includes(ViewPartners) && (
                    <Route path="" element={<Partners />} />
                  )}
                  {user?.permissions?.includes(ManagePartners) && (
                    <Route path="create" element={<PartnerCru />} />
                  )}
                  {user?.permissions?.includes(ManagePartners) && (
                    <Route path="edit/:partnerId" element={<PartnerCru />} />
                  )}
                </Route>
                <Route path="resources" element={<Resources />} />
                <Route path="resources/sel" element={<Sel />} />
                <Route path="personalization" element={<Personalization />} />
                <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <Typography variant="h1">There's nothing here</Typography>
                      <Typography variant="subtitle1">
                        You must be lost
                      </Typography>
                    </main>
                  }
                />
              </Route>
            </Routes>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
        </UnauthenticatedTemplate>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
