import { useMemo } from "react";
import EChartsReact from "echarts-for-react";
import { Paper, Typography, useTheme } from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function ChartGenderRace({ status, slicerData }) {
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const data = slicerData;

  const genders = useMemo(() => {
    let distinctGenders = [];
    data?.forEach((d) => {
      d?.genders?.forEach((gc) => {
        if (!distinctGenders?.includes(gc.gender))
          distinctGenders.push(gc.gender);
      });
    });
    return distinctGenders;
  }, [data]);

  const chartOptions = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    xAxis: {
      type: "category",
      data: data?.map((d) => d.race),
    },
    yAxis: {
      type: "value",
    },
    legend: {
      orient: "vertical",
      bottom: "bottom",
      textStyle: {
        color: theme.palette.text.secondary,
      },
    },
    series: genders?.map((g, i) => {
      return {
        color: status
          ? theme.palette.text.secondary
          : theme.palette.chart && theme.palette.chart[i],
        name: g,
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        data: data?.map((d) => {
          return d.genders?.find((gc) => gc.gender == g)?.students;
        }),
      };
    }),
  };

  return (
    <Paper sx={commonStyles.paperPadding}>
      <Typography variant="h6" color={theme.palette.text.secondary}>
        Students by Gender/Race
      </Typography>
      <EChartsReact option={chartOptions} />
    </Paper>
  );
}
