import { Grid } from "@mui/material";
import { useOutletContext } from "react-router";
import TableEnrollment from "./TableEnrollment";

export default function InstructorReportsEnrollment() {
  const [slicers] = useOutletContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableEnrollment slicers={slicers} />
      </Grid>
    </Grid>
  );
}
